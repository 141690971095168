import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function parseSvgFromFile(file: File, onload: (value: string) => void) {
  let value: string;
  const reader = new FileReader();
  reader.onload = () => {
    if (typeof reader.result !== "string")
      throw new Error("Unexpected file type");
    value = reader.result;
    // Filters out xml prolog (otherwise SVGInline does not work) and html comments
    value = value.replace(/((\?xml)+[^>]+>)|((<!--)+[^>]+-->)/g, "");
    // Also remove any line breaks
    value = value.replace(/\r?\n|\r/g, "");
  };
  reader.onloadend = () => {
    if (reader.readyState === 2) {
      onload(value);
    }
  };
  reader.onabort = () => console.error("file reading was aborted");
  reader.onerror = () => console.error("file reading has failed");

  reader.readAsText(file);
}

export function parseBinaryImageFromFile(
  file: File,
  onload: (value: string) => void,
) {
  let value: string;
  const reader = new FileReader();
  reader.onload = () => {
    const img = new Image();
    img.src = reader.result?.toString() ?? "";
    (img.onload = () => {
      const elem = document.createElement("canvas");
      const height = 60;
      const scaleFactor = height / img.height;
      elem.height = height;
      elem.width = img.width * scaleFactor;
      const ctx = elem.getContext("2d");
      if (!ctx) throw new Error("Could not get 2d context");
      // img.width and img.height will contain the original dimensions
      ctx.drawImage(img, 0, 0, img.width * scaleFactor, height); // height * scaleFactor
      value = ctx.canvas.toDataURL(file.type, 1);

      onload(value);
    }),
      (reader.onerror = (error) => console.error(error));
  };
  reader.onloadend = () => {
    if (reader.readyState === 2) {
      onload(value);
    }
  };
  reader.onabort = () => console.error("file reading was aborted");
  reader.onerror = () => console.error("file reading has failed");

  reader.readAsDataURL(file);
}
