import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { FieldPath, FieldValues, useFormContext } from "react-hook-form";
import { Input } from "@/components/ui/input";

const HEX_COLOR_REGEX = /^#[0-9a-fA-F]{6}$/;

export function HexColorInput<T extends FieldValues>({
  name,
  label,
}: Readonly<{ name: FieldPath<T>; label: string }>) {
  const form = useFormContext<T>();

  const colorHex = form.getValues(name) as string;
  const sampleColor = HEX_COLOR_REGEX.test(colorHex) ? colorHex : "#a8a8a8";

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="h-20 w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <div className="relative">
              <div
                style={{ background: sampleColor }}
                className="absolute right-2 top-1/2 size-6 -translate-y-1/2 rounded-full bg-red-800"
              />
              <Input
                ref={field.ref}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value as string}
                placeholder="#000000"
                autoComplete="off"
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
