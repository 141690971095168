let labelDebug: "PRINT" | "SKIP" | "DEBUG" = "PRINT";
if (import.meta.env.VITE_LABEL_DEBUG === "SKIP") {
  labelDebug = "SKIP";
} else if (import.meta.env.VITE_LABEL_DEBUG === "DEBUG") {
  labelDebug = "DEBUG";
}
export const ENV = {
  apiUrl: import.meta.env.VITE_API_URL ?? "http://localhost:5050",
  auth: {
    clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID,
    authority: import.meta.env.VITE_AZURE_AD_AUTHORITY,
    iss: import.meta.env.VITE_AZURE_AD_ISS,
  },
  printer: {
    labelDebug,
    mock: import.meta.env.VITE_PRINTER_MOCK === "true",
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
  environment: import.meta.env.MODE,
  release: import.meta.env.VITE_RELEASE,
  portalUrl: import.meta.env.VITE_PORTAL_URL,
};
