import { DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTranslation } from "react-i18next";
import { useModal } from "@/lib/modals/useModal";
import { usePrinter } from "@/lib/hooks/printing/usePrinter";
import {
  selectedPrinterAtom,
  useQueryPrinterList,
} from "@/lib/hooks/printing/useQueryPrinterList";

export function InboundChangePrinterModal() {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.change-printer",
  });

  const { hideModal } = useModal();

  const { isPrinterReady } = usePrinter();
  const [selectedPrinter, setSelectedPrinter] = useAtom(selectedPrinterAtom);
  const { data: printerList, isPending } = useQueryPrinterList();

  const [localPrinter, setLocalPrinter] = useState<string>(selectedPrinter);

  const handleSubmit = () => {
    setSelectedPrinter(localPrinter);
    hideModal();
  };

  useEffect(() => {
    if (localPrinter) return;
    setLocalPrinter(selectedPrinter);
  }, [localPrinter, selectedPrinter]);

  let printerPlaceholder = t("select-printer");
  if (!isPrinterReady) printerPlaceholder = t("connecting");
  else if (isPending) printerPlaceholder = t("loading");
  else if (printerList?.length === 0)
    printerPlaceholder = t("no-printer-found");

  const showValue = isPrinterReady && printerList && printerList?.length > 0;

  const isValidPrinter = useMemo(() => {
    return printerList?.includes(localPrinter);
  }, [printerList, localPrinter]);

  return (
    <DialogContent className="flex flex-col gap-6 sm:max-w-md">
      <DialogTitle>{t("select-printer")}</DialogTitle>
      <div className="grid w-full gap-1.5">
        <Label>{t("printer")}</Label>
        <Select
          onValueChange={setLocalPrinter}
          value={showValue ? localPrinter : undefined}
        >
          <SelectTrigger>
            <SelectValue placeholder={selectedPrinter && printerPlaceholder} />
          </SelectTrigger>
          <SelectContent>
            {printerList?.map((printerName) => (
              <SelectItem key={printerName} value={printerName}>
                {printerName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <Button variant="secondary" onClick={hideModal}>
          {t("cancel")}
        </Button>
        <Button onClick={handleSubmit} disabled={!isValidPrinter}>
          {t("save")}
        </Button>
      </div>
    </DialogContent>
  );
}
