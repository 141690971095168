import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { loginRequest, msalConfig } from "@/authconfig";

const initMsalInstance = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  // Default to using the first account if no account is active on page load
  const activeAccount = msalInstance.getActiveAccount();
  const allAccounts = msalInstance.getAllAccounts();

  if (!activeAccount && allAccounts.length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(allAccounts[0]);
  }

  // Listen for sign-in event and set active account
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      const result = event.payload as AuthenticationResult;
      const account = result.account;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};

export const msalInstance = initMsalInstance();

declare global {
  interface Window {
    getToken: () => Promise<string>;
  }
}

window.getToken = async () => {
  const result = await msalInstance.acquireTokenSilent(loginRequest);
  return result.accessToken;
};
