import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { Asset, EOLBatchItem } from "@/api/generated/data-contracts";
import { apiClient } from "@/api";

type QueryKey = ["device", "by-id", number | undefined];

export function useQueryDeviceById<TData = Asset>(
  id: number | undefined,
  options?: Omit<
    UseQueryOptions<Asset, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<Asset, unknown, TData, QueryKey>({
    ...options,
    queryKey: ["device", "by-id", id],
    queryFn: async () => {
      const response = await apiClient.assetsV2Detail(id!);
      if (!response.ok) {
        throw new Error("Failed to fetch device by AFI code");
      }

      return response.data;
    },
    enabled: !!id && (options?.enabled ?? true),
  });
}

type QueryKeyOld = ["device", "by-id-old", number | undefined];

export function useQueryDeviceByIdOld<TData = EOLBatchItem>(
  id: number | undefined,
  options?: Omit<
    UseQueryOptions<EOLBatchItem, unknown, TData, QueryKeyOld>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<EOLBatchItem, unknown, TData, QueryKeyOld>({
    ...options,
    queryKey: ["device", "by-id-old", id],
    queryFn: async () => {
      const response = await apiClient.assetsDetail(id!);
      if (!response.ok) {
        throw new Error("Failed to fetch device by AFI code");
      }

      return response.data;
    },
    enabled: !!id && (options?.enabled ?? true),
  });
}
