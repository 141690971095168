import * as z from "zod";

export const partnerInfoStepSchema = z.object({
  name: z.string().min(1),
  email: z.string().email(),
  address: z.object({
    street: z.string().optional(),
    postalCode: z.string().optional(),
    city: z.string().optional(),
  }),
});

export const disclaimerStepSchema = z.object({
  dislaimerFin: z.string().optional(),
  dislaimerEng: z.string().optional(),
});

export const themeStepSchema = z.object({
  darkColor: z.string().regex(/^#[0-9a-fA-F]{6}$/),
  lightColor: z.string().regex(/^#[0-9a-fA-F]{6}$/),
  logo: z.string().min(1),
});

export const newPartnerSchema = z
  .object({})
  .merge(partnerInfoStepSchema)
  .merge(disclaimerStepSchema)
  .merge(themeStepSchema);

export type PartnerInfoStepValues = z.infer<typeof partnerInfoStepSchema>;
export type DisclaimerStepValues = z.infer<typeof disclaimerStepSchema>;
export type ThemeStepValues = z.infer<typeof themeStepSchema>;
export type NewPartnerValues = z.infer<typeof newPartnerSchema>;
