import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { Asset } from "@/api/generated/data-contracts";
import { checkInExistingDevice, checkInNewDevice } from "@/api/CheckInApi";
import { ScannedDevice } from "@/views/inbound/inboundState";

interface MutationVariables {
  device: ScannedDevice;
}

export function useMutationCheckInDevice(
  options?: Omit<
    UseMutationOptions<Asset, unknown, MutationVariables>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<Asset, unknown, MutationVariables>({
    ...options,
    mutationFn: async ({ device }) => {
      const checkedInDevice = device.id
        ? await checkInExistingDevice(device.id)
        : await checkInNewDevice({
            orderId: device.orderId,
            manufacturer: device.manufacturer,
            readSerialNumber: device.serialNumber,
            classificationCategoryId: device.categoryId,
            productName: device.model,
            additionalInfo: device.extraItems,
          });
      queryClient.invalidateQueries({ queryKey: ["devices"] });
      queryClient.invalidateQueries({ queryKey: ["device-stage-changes"] });
      return checkedInDevice;
    },
  });
}
