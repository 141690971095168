import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import * as z from "zod";
import {
  FormField,
  FormItem,
  FormControl,
  Form,
  FormMessageTranslated,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormEvent } from "react";
import { toast } from "sonner";
import { EndCustomer } from "@/api/generated/data-contracts";
import { useMutateDeleteEndCustomer } from "@/lib/hooks/query/endcustomer/useMutateDeleteEndCustomer";

const FormSchema = z
  .object({
    endCustomerName: z.string(),
    typedEndCustomerName: z.string(),
  })
  .refine((data) => data.endCustomerName === data.typedEndCustomerName, {
    message:
      "common.modal.delete-end-customer.typed-end-customer-name-does-not-match",
    path: ["typedEndCustomerName"],
  });

type FormValues = z.infer<typeof FormSchema>;

interface DeleteEndCustomerModalProps {
  endCustomer: EndCustomer;
}

export function DeleteEndCustomerModal({
  endCustomer,
}: Readonly<DeleteEndCustomerModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.delete-end-customer",
  });
  const { hideModal } = useModal();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      endCustomerName: endCustomer.name!,
      typedEndCustomerName: "",
    },
  });

  const mutateDeleteEndCustomer = useMutateDeleteEndCustomer();

  const handleCancel = () => {
    hideModal();
  };

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      await mutateDeleteEndCustomer.mutateAsync({
        endCustomerId: endCustomer.id!,
      });
      toast.success(
        t("end-customer-deleted", { endCustomerName: endCustomer.name }),
      );
      hideModal();
    } catch (error) {
      toast.error(t("something-went-wrong-when-deleting-end-customer"));
    }
  };

  const isSubmitDisabled =
    !form.formState.isValid || mutateDeleteEndCustomer.isPending;

  return (
    <DialogContent className="sm:max-w-md">
      <Form {...form}>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <DialogHeader className="flex flex-col items-center">
            <DialogTitle>
              {t("delete-new-end-customer", {
                endCustomerName: endCustomer.name,
              })}
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-6">
            <DialogDescription>
              {t("delete-end-customer-description")}
              <b>{" " + endCustomer.name}</b>
            </DialogDescription>
            <FormField
              control={form.control}
              name="typedEndCustomerName"
              render={({ field }) => (
                <FormItem className="h-20 w-full">
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessageTranslated />
                </FormItem>
              )}
            />
          </div>
          <DialogFooter className="mt-6 grid grid-cols-2 gap-5">
            <Button type="button" variant="secondary" onClick={handleCancel}>
              {t("cancel")}
            </Button>
            <Button
              variant="destructive"
              type="submit"
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            >
              {t("delete")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}
