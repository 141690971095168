import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import { Input } from "@/components/ui/input";
import { useCallback, useMemo, useState } from "react";
import { useQueryDevicesBySerialNumber } from "@/lib/hooks/query/device/useQueryDevicesBySerialNumber";
import { useDebounce } from "@uidotdev/usehooks";
import { Label } from "@/components/ui/label";
import { Loader2Icon, XIcon } from "lucide-react";
import { Asset } from "@/api/generated/data-contracts";
import { useScannedDevices } from "@/views/inbound/components/useScannedDevices";
import { targetOrderIdAtom } from "@/views/inbound/inboundState";
import { useAtomValue } from "jotai";

export interface ManualSerialNumberModalProps {
  serialNumber: string;
}

type SearchStates = "noQuery" | "loading" | "foundDevices" | "noDevices";

export function InspectDeviceSearchModal({
  serialNumber,
}: Readonly<ManualSerialNumberModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.device-search",
  });
  const { hideModal } = useModal();

  const { inboundDevice, inboundExtraDevice } = useScannedDevices();

  const [serialNumberValue, setSerialNumberValue] = useState(serialNumber);

  const debouncedSerialNumber = useDebounce(serialNumberValue, 500);

  const deviceQuery = useQueryDevicesBySerialNumber(debouncedSerialNumber);

  const handleCancel = () => {
    hideModal();
  };

  const handleInboundDevice = useCallback(
    async (device: Asset) => {
      hideModal();
      inboundDevice(device);
    },
    [hideModal, inboundDevice],
  );

  const handleInboundAsExtra = async () => {
    hideModal();
    inboundExtraDevice(serialNumberValue);
  };

  const searchState: SearchStates = useMemo(() => {
    if (!debouncedSerialNumber) return "noQuery";
    if (deviceQuery.isPending) return "loading";
    if (deviceQuery.isSuccess && !!deviceQuery.data?.length)
      return "foundDevices";
    if (!deviceQuery.data?.length) return "noDevices";
    return "noQuery";
  }, [
    debouncedSerialNumber,
    deviceQuery.data?.length,
    deviceQuery.isPending,
    deviceQuery.isSuccess,
  ]);

  return (
    <DialogContent className="sm:max-w-2xl">
      <DialogHeader className="flex flex-col items-center">
        <Button
          variant="ghost"
          size="icon"
          className="absolute right-0 top-0"
          onClick={handleCancel}
        >
          <XIcon />
        </Button>
        <DialogTitle>
          {!serialNumber && "Search for device by serial number"}
          {serialNumber && "No matching device found"}
        </DialogTitle>
        <DialogDescription>
          {!!serialNumber && t("device-with-given-serial-was-not-found")}
          {!serialNumber && t("search-for-device-to-inbound")}
        </DialogDescription>
      </DialogHeader>
      <div className="flex flex-col gap-6">
        <Label className="flex flex-col gap-2">
          {!!serialNumber && t("scanned-serial-number")}
          {!serialNumber && t("serial-number")}
          <Input
            value={serialNumberValue}
            onChange={(e) => setSerialNumberValue(e.target.value)}
            autoFocus
          />
        </Label>
        {searchState === "foundDevices" && (
          <DeviceList
            devices={deviceQuery.data!}
            onSelect={handleInboundDevice}
          />
        )}
        {searchState === "noDevices" && <NoDevices />}
        {searchState === "loading" && <Loading />}
      </div>
      <DialogFooter className="mt-6 grid grid-cols-1 gap-5">
        <Button
          variant="secondary"
          disabled={!serialNumberValue}
          onClick={handleInboundAsExtra}
        >
          {t("add-as-extra-device")}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}

function NoDevices() {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.device-search",
  });
  return (
    <span className="text-md text-center opacity-40">
      {t("serial-number-doesnt-match-any-device")}
    </span>
  );
}

function Loading() {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.device-search",
  });
  return (
    <div className="flex flex-col items-center gap-2 opacity-40">
      <Loader2Icon className="mx-auto h-6 w-6 animate-spin" />
      <span className="block">{t("searching-device")}</span>
    </div>
  );
}

function DeviceList({
  devices,
  onSelect,
}: {
  devices: Asset[];
  onSelect: (device: Asset) => void;
}) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.device-search",
  });
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col items-center gap-3 text-sm">
        <span className="text-center text-gray-400">
          {t("matches", { count: devices.length })}
        </span>
        <span className="text-center text-gray-400">
          {t("select-correct-device")}
        </span>
      </div>
      <div className="flex max-h-96 flex-col gap-3 overflow-y-auto">
        {devices.map((device) => (
          <DeviceListItem key={device.id} device={device} onSelect={onSelect} />
        ))}
      </div>
    </div>
  );
}

function DeviceListItem({
  device,
  onSelect,
}: {
  device: Asset;
  onSelect: (device: Asset) => void;
}) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.device-search",
  });
  const targetOrderId = useAtomValue(targetOrderIdAtom);
  const isInvalidOrder =
    targetOrderId !== undefined && device.orderId !== targetOrderId;

  return (
    <div
      key={device.id}
      className="flex items-center justify-between rounded bg-white px-6 py-3 shadow"
    >
      <div className="flex flex-col gap-2">
        <Label className="flex flex-wrap gap-2 text-gray-400">
          {t("serial-number")}
          <span className="text-nowrap text-black">{device.serialNumber}</span>
        </Label>

        <Label className="flex flex-wrap gap-2 text-gray-400">
          {t("order-no")}
          <span
            className={`text-nowrap  ${
              isInvalidOrder ? "text-warning" : "text-black"
            }`}
          >
            {device.orderId}
          </span>
        </Label>
        <Label className="flex flex-wrap gap-2 text-gray-400">
          {t("batch-no")}
          <span className="text-nowrap text-black">{device.batchId}</span>
        </Label>
        <Label className="flex flex-wrap gap-2 text-gray-400">
          {t("brand-and-model")}
          <span className="text-nowrap text-black">
            {device.manufacturer} {device.productName}
          </span>
        </Label>
      </div>
      <Button onClick={() => onSelect(device)} disabled={isInvalidOrder}>
        {isInvalidOrder ? t("not-in-order") : t("select")}
      </Button>
    </div>
  );
}
