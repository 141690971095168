import { useTranslation } from "react-i18next";
import { CardBase } from "@/components/CardBase";
import { Button } from "@/components/ui/button";
import {
  FieldPath,
  FieldValues,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { EndCustomer } from "@/api/generated/data-contracts";
import { useMutateUpdateEndCustomer } from "@/lib/hooks/query/endcustomer/useMutateUpdateEndCustomer";
import { toast } from "sonner";
import { FormEvent, MouseEvent } from "react";
import { Input } from "@/components/ui/input";

const endCustomerGeneralFormSchema = z.object({
  name: z.string().min(1),
  email: z.string().email(),
  address: z.object({
    street: z.string().optional(),
    postalCode: z.string().optional(),
    city: z.string().optional(),
  }),
});
type EndCustomerGeneralFormValues = z.infer<
  typeof endCustomerGeneralFormSchema
>;
export function EndCustomerGeneralSection({
  endCustomer,
}: Readonly<{ endCustomer: EndCustomer }>) {
  const { t } = useTranslation("", {
    keyPrefix: "customer-management.view.end-customer",
  });

  const endCustomeMutation = useMutateUpdateEndCustomer();

  const form = useForm<EndCustomerGeneralFormValues>({
    resolver: zodResolver(endCustomerGeneralFormSchema),
    mode: "onChange",
    defaultValues: {
      name: endCustomer.name!,
      email: endCustomer.contactPerson!.email!,
      address: {
        street: endCustomer.address?.street ?? "",
        postalCode: endCustomer.address?.postalCode ?? "",
        city: endCustomer.address?.city ?? "",
      },
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const newEndCustomer: EndCustomer = {
      ...endCustomer,
      name: form.getValues("name"),
      contactPerson: {
        ...endCustomer.contactPerson!,
        email: form.getValues("email"),
      },
      address: {
        street: form.getValues("address.street"),
        postalCode: form.getValues("address.postalCode"),
        city: form.getValues("address.city"),
      },
    };
    try {
      await endCustomeMutation.mutateAsync(newEndCustomer);
      toast.success(
        t("end-customer-updated", { endCustomerName: newEndCustomer.name }),
      );
    } catch (error) {
      toast.error(t("something-went-wrong-when-updating-end-customer"));
    }
  };

  const handleReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    form.reset();
  };

  const isSubmitDisabled = !form.formState.isValid; //|| mutateCreateEndCustomer.isPending

  return (
    <CardBase>
      <Form {...form}>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <div className="flex">
            <h2 className="text-xl font-medium">{t("general")}</h2>
            <div className="ml-auto flex gap-3">
              <Button type="submit" disabled={isSubmitDisabled}>
                {t("save")}
              </Button>
              <Button type="reset" variant="outline" onClick={handleReset}>
                {t("reset")}
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <TextInput label={t("company-name") + " *"} name="name" />
            <TextInput label={t("email") + " *"} name="email" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <TextInput label={t("street")} name="address.street" />
            <TextInput label={t("postal-code")} name="address.postalCode" />
            <TextInput label={t("city")} name="address.city" />
          </div>
        </form>
      </Form>
    </CardBase>
  );
}

interface TextInputProps<T extends FieldValues> {
  name: FieldPath<T>;
  label: string;
  disabled?: boolean;
}

export function TextInput<T extends FieldValues>({
  name,
  label,
  disabled,
}: Readonly<TextInputProps<T>>) {
  const form = useFormContext<T>();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="h-20 w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input {...field} disabled={disabled} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
