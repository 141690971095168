import { useAtom } from "jotai";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import { useTranslation } from "react-i18next";
import { usePrinter } from "@/lib/hooks/printing/usePrinter";
import {
  selectedPrinterAtom,
  useQueryPrinterList,
} from "@/lib/hooks/printing/useQueryPrinterList";

export function PrinterSelect() {
  const { t } = useTranslation("", {
    keyPrefix: "common.component.printer-select",
  });
  const { data: printerList, isPending } = useQueryPrinterList();
  const { isPrinterReady } = usePrinter();
  const [selectedPrinter, setSelectedPrinter] = useAtom(selectedPrinterAtom);

  let printerPlaceholder = t("select-printer");
  if (!isPrinterReady) printerPlaceholder = t("connecting");
  else if (isPending) printerPlaceholder = t("loading");
  else if (printerList?.length === 0)
    printerPlaceholder = t("no-printer-found");

  const showValue = isPrinterReady && printerList && printerList?.length > 0;

  return (
    <div className="grid w-full gap-1.5">
      <Label>{t("selected-printer")}</Label>
      <Select
        onValueChange={setSelectedPrinter}
        value={showValue ? selectedPrinter : undefined}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder={printerPlaceholder} />
        </SelectTrigger>
        <SelectContent>
          {printerList?.map((printerName) => (
            <SelectItem key={printerName} value={printerName}>
              {printerName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
