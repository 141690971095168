import { atom } from "jotai";
import { atomWithReset, atomWithStorage } from "jotai/utils";

export const isInboundHelpOpenAtom = atomWithStorage("showInboundHelp", false);

export const isAutoPrintAtom = atom<boolean>(false);

export const targetOrderIdAtom = atomWithReset<number | undefined>(undefined);

export const scannedDevicesAtom = atomWithReset<ScannedDevice[]>([]);

export interface ScannedDevice {
  id?: number;
  eolTrackingNumber?: string;
  checkInTime?: Date;
  orderId?: number;
  categoryId?: number;
  manufacturer?: string;
  model?: string;
  serialNumber: string;
  assetNumber?: string; // Device reference number
  extraItems?: string; // Additional information

  // State
  state: "success" | "error" | "loading" | "missing";
  isPrinted: boolean;
  isExtra: boolean;
}
