import React from "react";
import { twMerge } from "tailwind-merge";

/**
  A loading spinner component.

  @param props.className - The class name to apply to the spinner.

  @example
  ```tsx
  import { LoadingSpinner } from '@/components/LoadingSpinner'

  const MyComponent = () => {
    return (
      <LoadingSpinner className='size-28'/>
    )
  }
  ```
*/

export const LoadingSpinner = React.forwardRef<
  HTMLDivElement,
  React.ButtonHTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={twMerge(
        "animate-spin rounded-full bg-[conic-gradient(var(--tw-gradient-stops))] from-black to-white",
        className,
      )}
      {...props}
    />
  );
});

LoadingSpinner.displayName = "LoadingSpinner";
