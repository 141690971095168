import {
  InboxArrowDownIcon,
  ArrowRightStartOnRectangleIcon,
  UsersIcon,
  SparklesIcon,
  MagnifyingGlassCircleIcon,
  CubeIcon,
  ChartBarSquareIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Link, useLocation } from "react-router-dom";

import logo from "../assets/also_holding_ag_logo_square 1.png";
import { Separator } from "./ui/separator";

export function NavDrawer() {
  return (
    <NavigationMenu className="w-min flex-grow-0 bg-[#fff]">
      <NavigationMenuList>
        <NavigationItem href="/" className="mb-6">
          <img src={logo} alt="" className="size-12" />
        </NavigationItem>
        <NavigationItem href="/monitoring">
          <ChartBarSquareIcon className="size-6" />
        </NavigationItem>
        <Separator />
        <NavigationItem href="/inbound">
          <InboxArrowDownIcon className="size-6" />
        </NavigationItem>
        <NavigationItem href="/cleanup">
          <SparklesIcon className="size-6" />
        </NavigationItem>
        <NavigationItem href="/inspect">
          <MagnifyingGlassCircleIcon className="size-6" />
        </NavigationItem>
        <NavigationItem href="/interim-storage">
          <CubeIcon className="size-6" />
        </NavigationItem>
        <NavigationItem href="/client-depot">
          <WalletIcon className="size-6" />
        </NavigationItem>
      </NavigationMenuList>

      <div className="mt-auto" />

      <NavigationMenuList>
        <NavigationItem href="/customer-management">
          <UsersIcon className="size-6" />
        </NavigationItem>
        {/* <NavigationItem href="/settings">
          <Cog6ToothIcon className="size-6" />
        </NavigationItem> */}
      </NavigationMenuList>

      <div className="size-12 h-max py-6">
        <Separator />
      </div>

      <NavigationMenuList>
        <NavigationItem href="/logout">
          <ArrowRightStartOnRectangleIcon className="size-6" />
        </NavigationItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

interface NavigationItemProps
  extends React.ComponentPropsWithoutRef<typeof NavigationMenuItem> {
  href: string;
}

function NavigationItem({
  children,
  href,
  ...props
}: Readonly<NavigationItemProps>) {
  const { pathname } = useLocation();
  const isActive = href === "/" ? pathname === href : pathname.startsWith(href);
  return (
    <NavigationMenuItem {...props}>
      <NavigationMenuLink
        asChild
        active={isActive}
        className={navigationMenuTriggerStyle()}
      >
        <Link to={href}>{children}</Link>
      </NavigationMenuLink>
    </NavigationMenuItem>
  );
}
