import { useQuery } from "@tanstack/react-query";
import { PDFPage, PDFFont, RGB, rgb, PDFDocument } from "pdf-lib";
import { code128 } from "gs1-128-encoder";

export function addTextToPage(
  page: PDFPage,
  text: string,
  x: number,
  y: number,
  font: PDFFont,
  size: number,
  color?: RGB,
) {
  page.drawText(text, {
    x: x,
    y: page.getHeight() - y - font.heightAtSize(size),
    size: size,
    font: font,
    color: color ?? rgb(0, 0, 0),
  });
}

export function useFont(fontSrc: string, fontName: string) {
  return useQuery({
    queryKey: ["font", fontName],
    queryFn: async () => {
      return fetch(fontSrc, { cache: "force-cache" }).then((res) =>
        res.arrayBuffer(),
      );
    },
    staleTime: Infinity,
  });
}

export function formatCode39Data(data: string) {
  return `*${data}*`;
}

export function formatCode128Data(data: string) {
  return code128.encodeForBarcodeFont(data);
}

export type FontSet = {
  barcodeFont: ArrayBuffer;
  helveticaFont: ArrayBuffer;
};

export type Fonts = {
  barcodeFont: PDFFont;
  helveticaFont: PDFFont;
};

export async function embedFonts(pdf: PDFDocument, fonts: FontSet) {
  const barcodeFont = await pdf.embedFont(fonts.barcodeFont, { subset: true });
  const helveticaFont = await pdf.embedFont(fonts.helveticaFont, {
    subset: true,
  });
  return { barcodeFont, helveticaFont } as Fonts;
}
