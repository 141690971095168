import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { EndCustomer } from "../../../../api/generated/data-contracts";
import { apiClient } from "@/api";

type QueryFnData = EndCustomer;
type QueryKey = ["endCustomer", number | undefined];

export function useQueryEndCustomerById<TData = QueryFnData>(
  endCustomerId: number | undefined,
  options?: Omit<
    UseQueryOptions<QueryFnData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<QueryFnData, unknown, TData, QueryKey>({
    staleTime: 1000 * 60 * 10, // 10 minutes
    ...options,
    queryKey: ["endCustomer", endCustomerId],
    queryFn: async () => {
      const response = await apiClient.endCustomersDetail(endCustomerId!);
      return response.data;
    },
    enabled: !!endCustomerId && (options?.enabled ?? true),
  });
}
