import { useTranslation } from "react-i18next";
import { Form } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormEvent } from "react";
import { useWizard } from "react-use-wizard";
import {
  DisclaimerStepValues,
  disclaimerStepSchema,
} from "@/lib/modals/partner/CreatePartnerModal/newPartnerSchema";
import { StepProps } from "@/lib/modals/partner/CreatePartnerModal/steps/types";
import { CreatePartnerFooter } from "@/lib/modals/partner/CreatePartnerModal/components/CreatePartnerFooter";
import { MultilineTextInput } from "@/lib/modals/partner/CreatePartnerModal/components/MultilineTextInput";

const DEFAULT_DISCLAIMER_FIN =
  "Vastaanottaja ei vastaa palautuneista laitteista, joita ei ole ilmoitettu palautuslomakkeessa.";
const DEFAULT_DISCLAIMER_ENG =
  "Recipient takes no responsibility for devices that have not been reported through the return order form.";

export function DisclaimerStep({
  newPartner,
  setNewPartner,
}: Readonly<StepProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-partner",
  });

  const { nextStep, previousStep } = useWizard();

  const form = useForm<DisclaimerStepValues>({
    resolver: zodResolver(disclaimerStepSchema),
    mode: "onChange",
    defaultValues: {
      dislaimerFin: DEFAULT_DISCLAIMER_FIN,
      dislaimerEng: DEFAULT_DISCLAIMER_ENG,
      ...newPartner,
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const values = form.getValues();
    setNewPartner(values);
    nextStep();
  };

  const handleBack = () => {
    const values = form.getValues();
    setNewPartner(values);
    previousStep();
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <MultilineTextInput
            label={t("return-order-disclaimer-fin")}
            name="dislaimerFin"
          />
          <MultilineTextInput
            label={t("return-order-disclaimer-eng")}
            name="dislaimerEng"
          />
        </div>
        <CreatePartnerFooter
          onBack={handleBack}
          onNext={handleSubmit}
          nextDisabled={!form.formState.isValid}
        />
      </form>
    </Form>
  );
}
