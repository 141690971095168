import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";

interface EndCustomerDeletionProps {
  endCustomerId: number;
}

export function useMutateDeleteEndCustomer(
  options?: Omit<
    UseMutationOptions<
      void,
      HttpResponse<void, Error>,
      EndCustomerDeletionProps
    >,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<void, HttpResponse<void, Error>, EndCustomerDeletionProps>(
    {
      ...options,
      mutationFn: async ({ endCustomerId }) => {
        await apiClient.endCustomersDelete(endCustomerId);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["endCustomer"] });
      },
    },
  );
}
