import { cn } from "@/lib/utils";
import { forwardRef } from "react";

interface PartnerLogoProps {
  partnerName?: string;
  logoData?: string | null;
}

export const PartnerLogo = forwardRef<
  HTMLImageElement,
  React.HTMLAttributes<HTMLImageElement> & PartnerLogoProps
>(({ className, logoData, partnerName, ...props }, ref) => {
  if (logoData?.slice(0, 100).includes("<svg")) {
    return (
      <img
        ref={ref}
        alt={`${partnerName} logo`}
        className={cn("object-contain", className)}
        src={`data:image/svg+xml;utf8,${encodeURIComponent(logoData)}`}
        {...props}
      />
    );
  } else if (logoData) {
    return (
      <img
        alt={`${partnerName} logo`}
        className={cn("object-contain", className)}
        src={logoData}
        {...props}
      />
    );
  }
  return partnerName;
});
PartnerLogo.displayName = "PartnerLogo";
