/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AfiListAssetInfo,
  Asset,
  AssetInfo,
  AssetPaginationResult,
  ClassificationCategory,
  ClassificationQuestion,
  ClassificationQuestionAnswer,
  ClassificationSet,
  Comment,
  CreateAsset,
  DeviceClassificationCategory,
  EOLBatch,
  EOLBatchItem,
  EmailConfirmationRequest,
  EndCustomer,
  EndCustomerCreation,
  Error,
  ForgotPasswordRequest,
  Inspection,
  LoginRequest,
  MissingAssets,
  NewPartner,
  NewProductCode,
  Order,
  OrderUpsertion,
  OrderV2,
  OrderV2PaginationResult,
  Partner,
  PriceListsDto,
  ProblemDetails,
  ProductCode,
  ProductCodeUpsert,
  ResetPasswordRequest,
  Settings,
  ShippingStatus,
  StateChangeV2,
  Storage,
  Tag,
  User,
  UserAccess,
  VerificationRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags AssetReport
   * @name AssetsReportList
   * @request GET:/api/assets/report
   */
  assetsReportList = (
    query?: {
      states?: number[];
      /** @format int32 */
      orderId?: number;
      /** @format int32 */
      batchId?: number;
      /** @format int32 */
      partnerId?: number;
      /** @format int32 */
      endCustomerId?: number;
      storageName?: string;
      referenceNumber?: string;
      serialNumber?: string;
      trackingNumber?: string;
      /** @format date-time */
      checkedInFrom?: string;
      /** @format date-time */
      checkedInTo?: string;
      /** @format date-time */
      classificationFrom?: string;
      /** @format date-time */
      classificationTo?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<File, Error>({
      path: `/api/assets/report`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsCreate
   * @request POST:/api/Assets
   * @deprecated
   */
  assetsCreate = (data: EOLBatchItem[], params: RequestParams = {}) =>
    this.request<EOLBatchItem[], any>({
      path: `/api/Assets`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsUpdate
   * @summary Updates specified assets. Throws an exception if an asset is found with the same EolTrackingNumber in a different batch
   * @request PUT:/api/Assets
   */
  assetsUpdate = (data: EOLBatchItem[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Assets`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsDetail
   * @request GET:/api/Assets/{AssetId}
   */
  assetsDetail = (assetId: number, params: RequestParams = {}) =>
    this.request<EOLBatchItem, any>({
      path: `/api/Assets/${assetId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsByTrackingNumberDetail
   * @request GET:/api/Assets/ByTrackingNumber/{EolTrackingNumber}
   */
  assetsByTrackingNumberDetail = (eolTrackingNumber: string, params: RequestParams = {}) =>
    this.request<EOLBatchItem, any>({
      path: `/api/Assets/ByTrackingNumber/${eolTrackingNumber}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsBySerialNumberDetail
   * @request GET:/api/Assets/BySerialNumber/{SerialNumber}
   */
  assetsBySerialNumberDetail = (serialNumber: string, params: RequestParams = {}) =>
    this.request<EOLBatchItem, any>({
      path: `/api/Assets/BySerialNumber/${serialNumber}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsV2Detail
   * @request GET:/api/assets/v2/{AssetId}
   */
  assetsV2Detail = (assetId: number, params: RequestParams = {}) =>
    this.request<Asset, Error>({
      path: `/api/assets/v2/${assetId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsList
   * @request GET:/api/assets
   */
  assetsList = (
    query?: {
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      PageNumber?: number;
      /**
       * @format int32
       * @min 10
       * @max 1000
       */
      PageSize?: number;
      /** @format int32 */
      batchId?: number;
      /** @format int32 */
      orderId?: number;
      /** @format int32 */
      endCustomerId?: number;
      /** @format int32 */
      partnerId?: number;
      search?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<AssetPaginationResult, any>({
      path: `/api/assets`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsBySerialNumberV2List
   * @request GET:/api/assets/by-serial-number/v2
   */
  assetsBySerialNumberV2List = (
    query: {
      serialNumber: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Asset[], Error>({
      path: `/api/assets/by-serial-number/v2`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsByTrackingNumberV2Detail
   * @request GET:/api/assets/by-tracking-number/v2/{trackingNumber}
   */
  assetsByTrackingNumberV2Detail = (trackingNumber: string, params: RequestParams = {}) =>
    this.request<Asset, Error>({
      path: `/api/assets/by-tracking-number/v2/${trackingNumber}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsByTrackingNumberUpdate
   * @request PUT:/api/assets/by-tracking-number/{trackingNumber}
   */
  assetsByTrackingNumberUpdate = (trackingNumber: string, data: CreateAsset, params: RequestParams = {}) =>
    this.request<Asset, ProblemDetails>({
      path: `/api/assets/by-tracking-number/${trackingNumber}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsPreloadList
   * @request GET:/api/Assets/preload
   * @deprecated
   */
  assetsPreloadList = (params: RequestParams = {}) =>
    this.request<AssetInfo[], any>({
      path: `/api/Assets/preload`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsFetchBlanccoReportsList
   * @request GET:/api/Assets/fetchBlanccoReports
   */
  assetsFetchBlanccoReportsList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Assets/fetchBlanccoReports`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsByTrackingNumbersCreate
   * @summary Returns a list of assets by EolTrackingNumbers
   * @request POST:/api/Assets/ByTrackingNumbers
   */
  assetsByTrackingNumbersCreate = (data: string[][], params: RequestParams = {}) =>
    this.request<AfiListAssetInfo[], any>({
      path: `/api/Assets/ByTrackingNumbers`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsMassUpdateBatchSelectedAssetsInfosCreate
   * @request POST:/api/Assets/MassUpdateBatchSelectedAssets/infos
   */
  assetsMassUpdateBatchSelectedAssetsInfosCreate = (data: string[][], params: RequestParams = {}) =>
    this.request<Record<string, number>, any>({
      path: `/api/Assets/MassUpdateBatchSelectedAssets/infos`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsMassUpdateBatchSelectedAssetsStatesCreate
   * @request POST:/api/Assets/MassUpdateBatchSelectedAssets/states
   */
  assetsMassUpdateBatchSelectedAssetsStatesCreate = (
    data: string[][],
    query?: {
      /** @format int32 */
      newState?:
        | 0
        | 10
        | 100
        | 110
        | 120
        | 200
        | 210
        | 220
        | 290
        | 300
        | 400
        | 405
        | 410
        | 420
        | 430
        | 450
        | 451
        | 452
        | 453
        | 454
        | 455
        | 456
        | 457
        | 458
        | 459
        | 460
        | 461;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, number>, any>({
      path: `/api/Assets/MassUpdateBatchSelectedAssets/states`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthLoginCreate
   * @request POST:/api/Auth/login
   */
  authLoginCreate = (data: LoginRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Auth/login`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthPostSsoLoginCreate
   * @request POST:/api/auth/post-sso-login
   */
  authPostSsoLoginCreate = (params: RequestParams = {}) =>
    this.request<User, ProblemDetails>({
      path: `/api/auth/post-sso-login`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthVerifyCreate
   * @request POST:/api/Auth/verify
   */
  authVerifyCreate = (data: VerificationRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Auth/verify`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthForgotPasswordCreate
   * @request POST:/api/Auth/forgotPassword
   */
  authForgotPasswordCreate = (data: ForgotPasswordRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Auth/forgotPassword`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthResetPasswordCreate
   * @request POST:/api/Auth/resetPassword
   */
  authResetPasswordCreate = (data: ResetPasswordRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Auth/resetPassword`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthConfirmEmailCreate
   * @request POST:/api/Auth/confirmEmail
   */
  authConfirmEmailCreate = (data: EmailConfirmationRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Auth/confirmEmail`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name PartnersBatchesDetail
   * @request GET:/api/partners/{partnerId}/batches
   * @deprecated
   */
  partnersBatchesDetail = (
    partnerId: number,
    query?: {
      /** @format date-time */
      orderCreatedBegin?: string;
      /** @format date-time */
      orderCreatedEnd?: string;
      /** @format date-time */
      orderModifiedBegin?: string;
      /** @format date-time */
      orderModifiedEnd?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, EOLBatch>, any>({
      path: `/api/partners/${partnerId}/batches`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name EndcustomersBatchesDetail
   * @request GET:/api/endcustomers/{endCustomerId}/batches
   * @deprecated
   */
  endcustomersBatchesDetail = (
    endCustomerId: number,
    query?: {
      /** @format date-time */
      orderCreatedBegin?: string;
      /** @format date-time */
      orderCreatedEnd?: string;
      /** @format date-time */
      orderModifiedBegin?: string;
      /** @format date-time */
      orderModifiedEnd?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, EOLBatch>, any>({
      path: `/api/endcustomers/${endCustomerId}/batches`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesDetail
   * @request GET:/api/Batches/{id}
   * @deprecated
   */
  batchesDetail = (id: number, params: RequestParams = {}) =>
    this.request<EOLBatch, any>({
      path: `/api/Batches/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesList
   * @request GET:/api/Batches
   * @deprecated
   */
  batchesList = (
    data: number[],
    query?: {
      /** @default false */
      showRemoved?: boolean;
      /** @default false */
      ignoreAssets?: boolean;
      /** @format date-time */
      orderCreatedBegin?: string;
      /** @format date-time */
      orderCreatedEnd?: string;
      /** @format date-time */
      orderModifiedBegin?: string;
      /** @format date-time */
      orderModifiedEnd?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, EOLBatch>, any>({
      path: `/api/Batches`,
      method: "GET",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesByOrderStateList
   * @summary Return all EOLBatches, filtered by provided orderStateIDs
   * @request GET:/api/Batches/ByOrderState
   */
  batchesByOrderStateList = (data: number[], params: RequestParams = {}) =>
    this.request<Record<string, EOLBatch>, any>({
      path: `/api/Batches/ByOrderState`,
      method: "GET",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesByAssetOrderStateList
   * @summary Return all EOLBatches, filtered by asset by provided orderStateIDs
   * @request GET:/api/Batches/ByAssetOrderState
   */
  batchesByAssetOrderStateList = (
    data: number[],
    query?: {
      /** @default true */
      includeAssets?: boolean;
      /** @format date-time */
      orderLastModifiedBegin?: string;
      /** @format date-time */
      orderLastModifiedEnd?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, EOLBatch>, any>({
      path: `/api/Batches/ByAssetOrderState`,
      method: "GET",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name HeadApi
   * @request HEAD:/api/Batches/{batchId}
   * @deprecated
   */
  headApi = (batchId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Batches/${batchId}`,
      method: "HEAD",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesApiBatchesAssetDestinationsDetail
   * @request GET:/api/Batches/api/Batches/{batchId}/AssetDestinations
   */
  batchesApiBatchesAssetDestinationsDetail = (batchId: string, params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/Batches/api/Batches/${batchId}/AssetDestinations`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesAssetsWithSerialNumberDetail
   * @request GET:/api/Batches/Assets/WithSerialNumber/{serialNumber}
   */
  batchesAssetsWithSerialNumberDetail = (serialNumber: string, params: RequestParams = {}) =>
    this.request<EOLBatch[], any>({
      path: `/api/Batches/Assets/WithSerialNumber/${serialNumber}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesAssetsWithTrackingCodeDetail
   * @request GET:/api/Batches/Assets/WithTrackingCode/{trackingCode}
   */
  batchesAssetsWithTrackingCodeDetail = (trackingCode: string, params: RequestParams = {}) =>
    this.request<EOLBatch[], any>({
      path: `/api/Batches/Assets/WithTrackingCode/${trackingCode}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name EndcustomerBatchDetail
   * @request GET:/api/endcustomer/{endCustomerId}/batch
   * @deprecated
   */
  endcustomerBatchDetail = (
    endCustomerId: number,
    query?: {
      originalId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/endcustomer/${endCustomerId}/batch`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description ADVISORY: If no query parameters are provided, this method will return a CSV file with all the assets in the system. This is cost heavy and definitely not recommended.
   *
   * @tags Batches
   * @name PartnersBatchesReportDetail
   * @summary Generates a CSV file consisting of assets that match the given query parameters
   * @request GET:/api/partners/{partnerId}/batches/report
   */
  partnersBatchesReportDetail = (
    partnerId: number,
    query?: {
      /** Full or partial name of the partner */
      partnerName?: string;
      /** Full or partial name of the end customer */
      endCustomerName?: string;
      /** Full or partial order number */
      orderNumber?: string;
      /** Full or partial reference number */
      referenceNumber?: string;
      /** Full or partial asset's storage number */
      storageNumber?: string;
      /** Full or partial asset's serial number */
      serialNumber?: string;
      /** Asset must be checked in on or after this date. Format: [Year 4 digits]-[Month 2 digits]-[Day 2 digits], e.g. 2018-03-13 */
      checkedInFrom?: string;
      /** Asset must be checked in on or before this date. Format: [Year 4 digits]-[Month 2 digits]-[Day 2 digits], e.g. 2018-03-13 */
      checkedInTo?: string;
      /** Asset must have been gone through classification on or after this date. Format: [Year 4 digits]-[Month 2 digits]-[Day 2 digits], e.g. 2018-03-13 */
      classificationFrom?: string;
      /** Asset must have been gone through classification on or before this date. Format: [Year 4 digits]-[Month 2 digits]-[Day 2 digits], e.g. 2018-03-13 */
      classificationTo?: string;
      /** List of asset states */
      states?: string[];
      /** Full or partial asset's AFI number */
      afiNumber?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/partners/${partnerId}/batches/report`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesReportList
   * @summary See comment above
   * @request GET:/api/Batches/report
   */
  batchesReportList = (
    query?: {
      partnerName?: string;
      endCustomerName?: string;
      orderNumber?: string;
      referenceNumber?: string;
      storageNumber?: string;
      serialNumber?: string;
      checkedInFrom?: string;
      checkedInTo?: string;
      classificationFrom?: string;
      classificationTo?: string;
      states?: string[];
      afiNumber?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/Batches/report`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesOrderIdDetail
   * @request GET:/api/batches/{batchId}/order-id
   */
  batchesOrderIdDetail = (batchId: number, params: RequestParams = {}) =>
    this.request<number, void>({
      path: `/api/batches/${batchId}/order-id`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Blancco
   * @name BlanccoCallbackDetail
   * @summary Callback for Blancco integration to update status when processed
   * @request GET:/api/blancco/callback/{extra}/{uuid}
   */
  blanccoCallbackDetail = (uuid: string, extra: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/blancco/callback/${extra}/${uuid}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Blancco
   * @name BlanccoStatusUpdateCreate
   * @request POST:/api/blancco/statusUpdate/{batchId}
   */
  blanccoStatusUpdateCreate = (batchId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/blancco/statusUpdate/${batchId}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckIn
   * @name AssetsCheckInCreate
   * @request POST:/api/assets/{assetId}/check-in
   */
  assetsCheckInCreate = (assetId: number, params: RequestParams = {}) =>
    this.request<Asset, Error>({
      path: `/api/assets/${assetId}/check-in`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckIn
   * @name AssetsCheckInCreate2
   * @request POST:/api/assets/check-in
   * @originalName assetsCheckInCreate
   * @duplicate
   */
  assetsCheckInCreate2 = (data: CreateAsset, params: RequestParams = {}) =>
    this.request<Asset, Error>({
      path: `/api/assets/check-in`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationCategories
   * @name OrdersClassificationCategoriesDetail
   * @request GET:/api/orders/{orderId}/classification-categories
   */
  ordersClassificationCategoriesDetail = (orderId: number, params: RequestParams = {}) =>
    this.request<DeviceClassificationCategory[], Error>({
      path: `/api/orders/${orderId}/classification-categories`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationCategories
   * @name BatchesClassificationcategoriesDetail
   * @request GET:/api/batches/{batchId}/classificationcategories
   */
  batchesClassificationcategoriesDetail = (batchId: number, params: RequestParams = {}) =>
    this.request<ClassificationCategory[], any>({
      path: `/api/batches/${batchId}/classificationcategories`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationCategories
   * @name ClassificationCategoriesList
   * @summary Returns all classification categories
   * @request GET:/api/ClassificationCategories
   */
  classificationCategoriesList = (params: RequestParams = {}) =>
    this.request<ClassificationCategory[], any>({
      path: `/api/ClassificationCategories`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationQuestionAnswers
   * @name ClassificationquestionanswersDetail
   * @request GET:/api/classificationquestionanswers/{assetId}
   */
  classificationquestionanswersDetail = (assetId: number, params: RequestParams = {}) =>
    this.request<ClassificationQuestionAnswer[], any>({
      path: `/api/classificationquestionanswers/${assetId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationQuestionAnswers
   * @name ClassificationquestionanswersList
   * @request GET:/api/classificationquestionanswers
   */
  classificationquestionanswersList = (data: number[], params: RequestParams = {}) =>
    this.request<ClassificationQuestionAnswer[], any>({
      path: `/api/classificationquestionanswers`,
      method: "GET",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationQuestionAnswers
   * @name ClassificationQuestionAnswersCreate
   * @request POST:/api/ClassificationQuestionAnswers
   */
  classificationQuestionAnswersCreate = (data: ClassificationQuestionAnswer[], params: RequestParams = {}) =>
    this.request<ClassificationQuestionAnswer[], any>({
      path: `/api/ClassificationQuestionAnswers`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationQuestionAnswers
   * @name ClassificationQuestionAnswersMassUploadCreate
   * @request POST:/api/ClassificationQuestionAnswers/MassUpload
   */
  classificationQuestionAnswersMassUploadCreate = (data: ClassificationQuestionAnswer[], params: RequestParams = {}) =>
    this.request<ClassificationQuestionAnswer[], any>({
      path: `/api/ClassificationQuestionAnswers/MassUpload`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationQuestions
   * @name BatchesClassificationquestionsDetail
   * @request GET:/api/batches/{batchId}/classificationquestions
   */
  batchesClassificationquestionsDetail = (batchId: number, params: RequestParams = {}) =>
    this.request<ClassificationQuestion[], any>({
      path: `/api/batches/${batchId}/classificationquestions`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationQuestions
   * @name ClassificationcategoriesClassificationquestionsDetail
   * @request GET:/api/classificationcategories/{id}/classificationquestions
   */
  classificationcategoriesClassificationquestionsDetail = (id: number, params: RequestParams = {}) =>
    this.request<ClassificationQuestion[], any>({
      path: `/api/classificationcategories/${id}/classificationquestions`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationQuestions
   * @name AssetsClassificationquestionsDetail
   * @request GET:/api/assets/{assetId}/classificationquestions
   */
  assetsClassificationquestionsDetail = (assetId: number, params: RequestParams = {}) =>
    this.request<ClassificationQuestion[], Error>({
      path: `/api/assets/${assetId}/classificationquestions`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationSet
   * @name ClassificationSetCreate
   * @request POST:/api/ClassificationSet
   */
  classificationSetCreate = (data: ClassificationSet, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/ClassificationSet`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Cleanup
   * @name AssetsCleanupCreate
   * @request POST:/api/assets/{trackingNumber}/cleanup
   */
  assetsCleanupCreate = (trackingNumber: string, params: RequestParams = {}) =>
    this.request<Asset, Error>({
      path: `/api/assets/${trackingNumber}/cleanup`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClientDepot
   * @name ProductCodesCreate
   * @request POST:/api/product-codes
   */
  productCodesCreate = (data: NewProductCode, params: RequestParams = {}) =>
    this.request<ProductCode, Error | void>({
      path: `/api/product-codes`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClientDepot
   * @name ProductCodesList
   * @request GET:/api/product-codes
   */
  productCodesList = (
    query?: {
      /** @format int32 */
      endCustomerId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProductCode[], any>({
      path: `/api/product-codes`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClientDepot
   * @name AssetsProductCodesCreate
   * @request POST:/api/assets/product-codes
   */
  assetsProductCodesCreate = (data: ProductCodeUpsert[], params: RequestParams = {}) =>
    this.request<void, Error | void>({
      path: `/api/assets/product-codes`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Comments
   * @name CommentsCreate
   * @summary Uploads a comment for the order
   * @request POST:/api/Comments
   */
  commentsCreate = (data: Comment, params: RequestParams = {}) =>
    this.request<Comment[], any>({
      path: `/api/Comments`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Comments
   * @name CommentsList
   * @summary Returns internal comments for the order
   * @request GET:/api/Comments
   */
  commentsList = (
    query?: {
      /**
       * ID of the order whose comments to fetch
       * @format int32
       */
      orderId?: number;
      /** Fetch internal or public comments */
      isInternal?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Comment[], any>({
      path: `/api/Comments`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersCreate
   * @request POST:/api/end-customers
   */
  endCustomersCreate = (data: EndCustomerCreation, params: RequestParams = {}) =>
    this.request<EndCustomer, Error>({
      path: `/api/end-customers`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersList
   * @request GET:/api/end-customers
   */
  endCustomersList = (
    query?: {
      /** @default false */
      showDisabled?: boolean;
      /** @format int32 */
      partnerId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<EndCustomer[], void>({
      path: `/api/end-customers`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersDetail
   * @request GET:/api/end-customers/{id}
   */
  endCustomersDetail = (id: number, params: RequestParams = {}) =>
    this.request<EndCustomer, Error | void>({
      path: `/api/end-customers/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name PartnersEndcustomersDetail
   * @summary Returns all enabled end customers visible to specified partner
   * @request GET:/api/partners/{partnerId}/endcustomers
   * @deprecated
   */
  partnersEndcustomersDetail = (partnerId: number, params: RequestParams = {}) =>
    this.request<EndCustomer[], any>({
      path: `/api/partners/${partnerId}/endcustomers`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersDetail2
   * @request GET:/api/EndCustomers/{id}
   * @originalName endCustomersDetail
   * @duplicate
   */
  endCustomersDetail2 = (id: number, params: RequestParams = {}) =>
    this.request<EndCustomer, any>({
      path: `/api/EndCustomers/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersDelete
   * @request DELETE:/api/EndCustomers/{id}
   */
  endCustomersDelete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/EndCustomers/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersList2
   * @summary Return all Customers, or those matching any ID provided
   * @request GET:/api/EndCustomers
   * @deprecated
   * @originalName endCustomersList
   * @duplicate
   */
  endCustomersList2 = (data: number[], params: RequestParams = {}) =>
    this.request<Record<string, EndCustomer>, any>({
      path: `/api/EndCustomers`,
      method: "GET",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersUpdate
   * @request PUT:/api/EndCustomers
   */
  endCustomersUpdate = (data: EndCustomer, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/EndCustomers`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name BatchesEndCustomersDetail
   * @request GET:/api/Batches/{batchId}/EndCustomers
   */
  batchesEndCustomersDetail = (batchId: number, params: RequestParams = {}) =>
    this.request<EndCustomer, any>({
      path: `/api/Batches/${batchId}/EndCustomers`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersApiEndCustomersTagCreate
   * @request POST:/api/EndCustomers/api/end-customers/{id}/tag
   */
  endCustomersApiEndCustomersTagCreate = (id: number, data: Tag, params: RequestParams = {}) =>
    this.request<Tag, void>({
      path: `/api/EndCustomers/api/end-customers/${id}/tag`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Erasure
   * @name ErasureAssetPdfDetail
   * @request GET:/api/erasure/asset/pdf/{assetId}
   */
  erasureAssetPdfDetail = (assetId: number, params: RequestParams = {}) =>
    this.request<File, void>({
      path: `/api/erasure/asset/pdf/${assetId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Erasure
   * @name ErasureBatchPdfDetail
   * @request GET:/api/erasure/batch/pdf/{batchId}
   */
  erasureBatchPdfDetail = (batchId: number, params: RequestParams = {}) =>
    this.request<File, void>({
      path: `/api/erasure/batch/pdf/${batchId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Inspection
   * @name AssetsInspectionDetail
   * @request GET:/api/assets/{trackingNumber}/inspection
   */
  assetsInspectionDetail = (trackingNumber: string, params: RequestParams = {}) =>
    this.request<Inspection, void>({
      path: `/api/assets/${trackingNumber}/inspection`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Inspection
   * @name AssetsInspectionUpdate
   * @request PUT:/api/assets/{trackingNumber}/inspection
   */
  assetsInspectionUpdate = (trackingNumber: string, data: Inspection, params: RequestParams = {}) =>
    this.request<Inspection, Error | void>({
      path: `/api/assets/${trackingNumber}/inspection`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InterimStorage
   * @name AssetsStorageCreate
   * @request POST:/api/assets/storage
   */
  assetsStorageCreate = (data: Storage, params: RequestParams = {}) =>
    this.request<Storage, Error>({
      path: `/api/assets/storage`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MissingAssets
   * @name OrdersMissingAssetsDetail
   * @request GET:/api/orders/{id}/missing-assets
   */
  ordersMissingAssetsDetail = (id: number, params: RequestParams = {}) =>
    this.request<MissingAssets, void>({
      path: `/api/orders/${id}/missing-assets`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MissingAssets
   * @name OrdersMissingAssetsCreate
   * @request POST:/api/orders/{id}/missing-assets
   */
  ordersMissingAssetsCreate = (id: number, params: RequestParams = {}) =>
    this.request<void, Error | void>({
      path: `/api/orders/${id}/missing-assets`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersCreate
   * @request POST:/api/orders
   */
  ordersCreate = (data: OrderUpsertion, params: RequestParams = {}) =>
    this.request<OrderV2, Error>({
      path: `/api/orders`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersList
   * @request GET:/api/orders
   */
  ordersList = (
    query?: {
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      PageNumber?: number;
      /**
       * @format int32
       * @min 10
       * @max 1000
       */
      PageSize?: number;
      /** @default false */
      showRemoved?: boolean;
      /** @format int32 */
      partnerId?: number;
      /** @format int32 */
      endCustomerId?: number;
      /** @format date-time */
      orderCreatedOrModifiedBegin?: string;
      /** @format date-time */
      orderCreatedOrModifiedEnd?: string;
      search?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderV2PaginationResult, Error>({
      path: `/api/orders`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersV2Update
   * @request PUT:/api/orders/v2/{id}
   */
  ordersV2Update = (id: number, data: OrderUpsertion, params: RequestParams = {}) =>
    this.request<OrderV2, Error | void>({
      path: `/api/orders/v2/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersDetail
   * @request GET:/api/orders/{id}
   */
  ordersDetail = (id: number, params: RequestParams = {}) =>
    this.request<OrderV2, void | Error>({
      path: `/api/orders/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersConfirmDetail
   * @request GET:/api/Orders/{orderId}/confirm
   */
  ordersConfirmDetail = (orderId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Orders/${orderId}/confirm`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersRejectDetail
   * @request GET:/api/Orders/{orderId}/reject
   */
  ordersRejectDetail = (orderId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Orders/${orderId}/reject`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersUpdate
   * @request PUT:/api/Orders
   */
  ordersUpdate = (data: Order, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Orders`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersUpdate2
   * @request PUT:/api/Orders/{orderId}
   * @originalName ordersUpdate
   * @duplicate
   */
  ordersUpdate2 = (
    orderId: number,
    query?: {
      /** @default "remove" */
      action?: string;
      /** @default "" */
      removalReason?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/Orders/${orderId}`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name HeadApi2
   * @request HEAD:/api/Orders/{orderId}
   * @originalName headApi
   * @duplicate
   */
  headApi2 = (orderId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Orders/${orderId}`,
      method: "HEAD",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersSendToMpgCreate
   * @request POST:/api/Orders/{orderId}/sendToMpg
   */
  ordersSendToMpgCreate = (orderId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Orders/${orderId}/sendToMpg`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersFetchmpgordersList
   * @summary Fetches all updates to orders from MPG
   * @request GET:/api/Orders/fetchmpgorders
   */
  ordersFetchmpgordersList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Orders/fetchmpgorders`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersList
   * @summary Returns all partners
   * @request GET:/api/Partners
   */
  partnersList = (params: RequestParams = {}) =>
    this.request<Record<string, Partner>, any>({
      path: `/api/Partners`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersCreate
   * @request POST:/api/Partners
   */
  partnersCreate = (data: NewPartner, params: RequestParams = {}) =>
    this.request<Partner, any>({
      path: `/api/Partners`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersUpdate
   * @request PUT:/api/Partners
   */
  partnersUpdate = (data: Partner, params: RequestParams = {}) =>
    this.request<Partner, any>({
      path: `/api/Partners`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersDetail
   * @summary Returns partner by PartnerId
   * @request GET:/api/Partners/{id}
   */
  partnersDetail = (id: number, params: RequestParams = {}) =>
    this.request<Partner, void>({
      path: `/api/Partners/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersCategoriesDetail
   * @summary Returns classification categories of the partner selected by PartnerId
   * @request GET:/api/Partners/{id}/categories
   */
  partnersCategoriesDetail = (id: number, params: RequestParams = {}) =>
    this.request<string[], void>({
      path: `/api/Partners/${id}/categories`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesCreate
   * @request POST:/api/prices/{partnerId}
   */
  pricesCreate = (partnerId: number, data: PriceListsDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/prices/${partnerId}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesReportDetail
   * @request GET:/api/prices/{partnerId}/Report
   */
  pricesReportDetail = (
    partnerId: number,
    query?: {
      endCustomerId?: number[];
      batchId?: string[];
      /** @format date-time */
      pricingPeriodFrom?: string;
      /** @format date-time */
      pricingPeriodTo?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/prices/${partnerId}/Report`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesOrderServicePricesDetail
   * @request GET:/api/prices/{partnerId}/orderServicePrices
   */
  pricesOrderServicePricesDetail = (partnerId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/prices/${partnerId}/orderServicePrices`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesCategoryPricesDetail
   * @request GET:/api/prices/{partnerId}/categoryPrices
   */
  pricesCategoryPricesDetail = (partnerId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/prices/${partnerId}/categoryPrices`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesEndStatePricesDetail
   * @request GET:/api/prices/{partnerId}/endStatePrices
   */
  pricesEndStatePricesDetail = (partnerId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/prices/${partnerId}/endStatePrices`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name PartnersSettingsDetail
   * @request GET:/api/partners/{partnerId}/settings
   */
  partnersSettingsDetail = (partnerId: number, params: RequestParams = {}) =>
    this.request<Settings, any>({
      path: `/api/partners/${partnerId}/settings`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name PartnersSettingsUpdate
   * @request PUT:/api/partners/{partnerId}/settings
   */
  partnersSettingsUpdate = (partnerId: number, data: Settings, params: RequestParams = {}) =>
    this.request<Settings, any>({
      path: `/api/partners/${partnerId}/settings`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name EndcustomersSettingsDetail
   * @request GET:/api/endcustomers/{endcustomerId}/settings
   */
  endcustomersSettingsDetail = (endcustomerId: number, params: RequestParams = {}) =>
    this.request<Settings, any>({
      path: `/api/endcustomers/${endcustomerId}/settings`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name EndcustomersSettingsUpdate
   * @request PUT:/api/endcustomers/{endcustomerId}/settings
   */
  endcustomersSettingsUpdate = (endcustomerId: number, data: Settings, params: RequestParams = {}) =>
    this.request<Settings, any>({
      path: `/api/endcustomers/${endcustomerId}/settings`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name EndcustomersAllsettingsDetail
   * @request GET:/api/endcustomers/{endcustomerId}/allsettings
   */
  endcustomersAllsettingsDetail = (endcustomerId: number, params: RequestParams = {}) =>
    this.request<Settings, any>({
      path: `/api/endcustomers/${endcustomerId}/allsettings`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipmentTracking
   * @name ShipmentTrackingUpdateShipmentsCreate
   * @request POST:/api/ShipmentTracking/updateShipments/{orderNumber}
   */
  shipmentTrackingUpdateShipmentsCreate = (orderNumber: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/ShipmentTracking/updateShipments/${orderNumber}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipmentTracking
   * @name ShipmentTrackingOrderDetail
   * @request GET:/api/ShipmentTracking/order/{orderId}
   */
  shipmentTrackingOrderDetail = (orderId: number, params: RequestParams = {}) =>
    this.request<ShippingStatus, ProblemDetails>({
      path: `/api/ShipmentTracking/order/${orderId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags StateChange
   * @name AssetsStateChangesDetail
   * @request GET:/api/assets/{id}/state-changes
   */
  assetsStateChangesDetail = (id: number, params: RequestParams = {}) =>
    this.request<StateChangeV2[], any>({
      path: `/api/assets/${id}/state-changes`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserCreate
   * @request POST:/api/User
   */
  userCreate = (data: User[], params: RequestParams = {}) =>
    this.request<User[], Error>({
      path: `/api/User`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserDetail
   * @request GET:/api/user/{id}
   */
  userDetail = (id: number, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/user/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserDelete
   * @request DELETE:/api/user/{id}
   */
  userDelete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/user/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserLoggedinuserList
   * @request GET:/api/user/loggedinuser
   */
  userLoggedinuserList = (params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/user/loggedinuser`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserUpdate
   * @request PUT:/api/user
   */
  userUpdate = (data: User, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/user`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name EndCustomerUsersDetail
   * @summary Lists all users and their access info for given end customer
   * @request GET:/api/endCustomer/{endCustomerId}/users
   */
  endCustomerUsersDetail = (endCustomerId: number, params: RequestParams = {}) =>
    this.request<UserAccess[], any>({
      path: `/api/endCustomer/${endCustomerId}/users`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name EndCustomerUsersUpdate
   * @summary Updates end customer's user's access
   * @request PUT:/api/endCustomer/{endCustomerId}/users/{email}
   */
  endCustomerUsersUpdate = (endCustomerId: number, email: string, data: UserAccess, params: RequestParams = {}) =>
    this.request<UserAccess, any>({
      path: `/api/endCustomer/${endCustomerId}/users/${email}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name EndCustomerUsersCreate
   * @summary Creates a new user access for an end customer
   * @request POST:/api/endCustomer/{endCustomerId}/users/{email}
   */
  endCustomerUsersCreate = (endCustomerId: number, email: string, data: UserAccess, params: RequestParams = {}) =>
    this.request<UserAccess, Error>({
      path: `/api/endCustomer/${endCustomerId}/users/${email}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name EndCustomerUsersDelete
   * @request DELETE:/api/endCustomer/{endCustomerId}/users/{email}
   */
  endCustomerUsersDelete = (endCustomerId: number, email: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/endCustomer/${endCustomerId}/users/${email}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PartnerUsersDetail
   * @request GET:/api/partner/{partnerId}/users
   */
  partnerUsersDetail = (partnerId: number, params: RequestParams = {}) =>
    this.request<UserAccess[], any>({
      path: `/api/partner/${partnerId}/users`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PartnerUsersUpdate
   * @request PUT:/api/partner/{partnerId}/users/{email}
   */
  partnerUsersUpdate = (partnerId: number, email: string, data: UserAccess, params: RequestParams = {}) =>
    this.request<UserAccess, any>({
      path: `/api/partner/${partnerId}/users/${email}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PartnerUsersCreate
   * @request POST:/api/partner/{partnerId}/users/{email}
   */
  partnerUsersCreate = (partnerId: number, email: string, data: UserAccess, params: RequestParams = {}) =>
    this.request<UserAccess, Error>({
      path: `/api/partner/${partnerId}/users/${email}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PartnerUsersDelete
   * @request DELETE:/api/partner/{partnerId}/users/{email}
   */
  partnerUsersDelete = (partnerId: number, email: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/partner/${partnerId}/users/${email}`,
      method: "DELETE",
      ...params,
    });
}
