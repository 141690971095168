import { useTranslation } from "react-i18next";
import { useModal } from "@/lib/modals/useModal";
import { Form } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormEvent } from "react";
import { useWizard } from "react-use-wizard";
import { StepProps } from "@/lib/modals/partner/CreatePartnerModal/steps/types";
import {
  PartnerInfoStepValues,
  partnerInfoStepSchema,
} from "@/lib/modals/partner/CreatePartnerModal/newPartnerSchema";
import { CreatePartnerFooter } from "@/lib/modals/partner/CreatePartnerModal/components/CreatePartnerFooter";
import { TextInput } from "@/views/customerManagement/components/EndCustomerGeneralSection";

export function PartnerInfoStep({
  newPartner,
  setNewPartner,
}: Readonly<StepProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-partner",
  });

  const { hideModal } = useModal();
  const { nextStep } = useWizard();

  const form = useForm<PartnerInfoStepValues>({
    resolver: zodResolver(partnerInfoStepSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      address: {
        street: "",
        postalCode: "",
        city: "",
      },
      ...newPartner,
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const values = form.getValues();
    setNewPartner(values);
    nextStep();
  };

  const handleCancel = () => {
    setNewPartner({});
    hideModal();
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <TextInput label={t("company-name") + " *"} name="name" />
          <TextInput label={t("email") + " *"} name="email" />
          <TextInput label={t("street")} name="address.street" />
          <TextInput label={t("postal-code")} name="address.postalCode" />
          <TextInput label={t("city")} name="address.city" />
        </div>
        <CreatePartnerFooter
          onBack={handleCancel}
          onNext={handleSubmit}
          nextDisabled={!form.formState.isValid}
        />
      </form>
    </Form>
  );
}
