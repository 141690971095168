export const LOCALE: Intl.LocalesArgument = "fi-FI";
export const LOCALE_STRING_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
};
export const LOCALE_DATE_STRING_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
export const LOCALE_TIME_STRING_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

type DateInput = Date | string | number;

// fix until api returns dates with Z
function addUtcIfNot(date: DateInput) {
  if (typeof date !== "string") return date;
  if (date.at(-1) === "Z") return date;
  return date + "Z";
}

export function dateToLocaleString(
  date: DateInput,
  options?: Intl.DateTimeFormatOptions,
) {
  return new Date(addUtcIfNot(date)).toLocaleString(LOCALE, {
    ...LOCALE_STRING_OPTIONS,
    ...options,
  });
}

export function dateToLocaleDate(
  date: DateInput,
  options?: Intl.DateTimeFormatOptions,
) {
  return new Date(addUtcIfNot(date)).toLocaleDateString(LOCALE, {
    ...LOCALE_DATE_STRING_OPTIONS,
    ...options,
  });
}

export function dateToLocaleTimeString(
  date: DateInput,
  options?: Intl.DateTimeFormatOptions,
) {
  return new Date(addUtcIfNot(date)).toLocaleTimeString(LOCALE, {
    ...LOCALE_TIME_STRING_OPTIONS,
    ...options,
  });
}
