import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { DeviceClassificationCategory } from "@/api/generated/data-contracts";
import { apiClient } from "@/api";

type QueryKey = ["device-categories", number | undefined | null];

export function useQueryDeviceCategories<
  TData = DeviceClassificationCategory[],
>(
  orderId: number | undefined | null,
  options?: Omit<
    UseQueryOptions<DeviceClassificationCategory[], unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<DeviceClassificationCategory[], unknown, TData, QueryKey>({
    staleTime: 1000 * 60 * 10, // 10 minutes
    ...options,
    queryKey: ["device-categories", orderId],
    queryFn: async () => {
      if (!orderId) throw new Error("orderId is required");
      const response =
        await apiClient.ordersClassificationCategoriesDetail(orderId);
      return response.data;
    },
    enabled: !!orderId && (options?.enabled ?? true),
  });
}
