import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";
import { EndCustomer } from "@/api/generated/data-contracts";

interface MutationVariables {
  id: number;
}

export function useMutationEndCustomerById(
  options?: Omit<
    UseMutationOptions<
      EndCustomer,
      HttpResponse<void, Error>,
      MutationVariables
    >,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<EndCustomer, HttpResponse<void, Error>, MutationVariables>(
    {
      ...options,
      mutationFn: async ({ id }) => {
        const existingOrder = queryClient.getQueryData<EndCustomer | undefined>(
          ["endCustomer", id],
        );
        if (existingOrder) return existingOrder;
        const response = await apiClient.endCustomersDetail(id);
        queryClient.setQueryData(["endCustomer", id], response.data);
        return response.data;
      },
    },
  );
}
