import { useTranslation } from "react-i18next";
import { DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useWizard } from "react-use-wizard";

export function CreatePartnerHeader() {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-partner",
  });
  const { activeStep, stepCount } = useWizard();
  return (
    <DialogHeader className="flex flex-col items-center">
      <DialogTitle>
        {t("create-new-partner")} {`${activeStep + 1}/${stepCount}`}
      </DialogTitle>
    </DialogHeader>
  );
}
