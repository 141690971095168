import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";
import {
  EndCustomer,
  EndCustomerCreation,
} from "@/api/generated/data-contracts";

export function useMutateCreateEndCustomer(
  options?: Omit<
    UseMutationOptions<
      EndCustomer,
      HttpResponse<void, Error>,
      EndCustomerCreation
    >,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<
    EndCustomer,
    HttpResponse<void, Error>,
    EndCustomerCreation
  >({
    ...options,
    mutationFn: async (newEndCustomer) => {
      const response = await apiClient.endCustomersCreate(newEndCustomer);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["endCustomer"] });
    },
  });
}
