import { Dialog } from "@/components/ui/dialog";
import { useModal } from "./useModal";
import { MODAL_COMPONENTS } from "./modalState";
import { useScannerController } from "@/lib/hooks/useScanner";
import { useEffect } from "react";

export function ModalPortal() {
  const { modalState, hideModal } = useModal();

  const { setScannerEnabled } = useScannerController();

  useEffect(() => {
    setScannerEnabled(!modalState.modalType);
  }, [modalState.modalType, setScannerEnabled]);

  const handleOpenChange = (isOpen: boolean) => {
    if (modalState.modalProps?.disableClose) return;
    if (!isOpen) {
      hideModal();
    }
  };

  if (!modalState.modalType) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[modalState.modalType];

  return (
    <Dialog onOpenChange={handleOpenChange} open={true}>
      {/* @ts-expect-error types not correct yet */}
      <ModalComponent {...modalState.modalProps} />
    </Dialog>
  );
}
