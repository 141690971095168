import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";
import { EndCustomer } from "@/api/generated/data-contracts";

export function useMutateUpdateEndCustomer(
  options?: Omit<
    UseMutationOptions<void, HttpResponse<void, Error>, EndCustomer>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<void, HttpResponse<void, Error>, EndCustomer>({
    ...options,
    mutationFn: async (endCustomer) => {
      await apiClient.endCustomersUpdate(endCustomer);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["endCustomer"] });
    },
  });
}
