import { RouterProvider } from "react-router";

import { router } from "@/Router";
import { Toaster } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ModalPortal } from "@/lib/modals/ModalPortal";

export const App = () => {
  return (
    <TooltipProvider>
      <RouterProvider router={router} />
      <ModalPortal />
      <Toaster position="top-right" />
    </TooltipProvider>
  );
};
