import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import { Asset } from "@/api/generated/data-contracts";

interface InboundSelectCorrectDeviceModalProps {
  devices: Asset[];
  serialNumber: string;
  onSelect: (device: Asset) => void;
  onCancel: () => void;
}

export function InboundSelectCorrectDeviceModal({
  serialNumber,
  devices,
  onSelect,
  onCancel,
}: Readonly<InboundSelectCorrectDeviceModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.select-correct-device",
  });
  const { hideModal } = useModal();

  const handleCancel = () => {
    onCancel();
    hideModal();
  };

  const handleSelect = (device: Asset) => {
    onSelect(device);
    hideModal();
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogTitle>{t("select-correct-device")}</DialogTitle>
      <DialogDescription>
        {t(
          "select-serial-number-has-multiple-matches-please-select-the-correct-device",
          { serialNumber },
        )}
      </DialogDescription>
      <div className="flex flex-col gap-3 divide-y-2 divide-solid">
        {devices.slice(0, 5).map((device) => (
          <div
            key={device.id}
            data-testid={`device-row-${device.orderId}`}
            className="flex gap-3 py-2"
          >
            <div className="flex flex-col">
              <label>
                {t("order-no")} <span>{device.orderId}</span>
              </label>
              <span>{device.manufacturer}</span>
              <span>{device.productName}</span>
            </div>
            <Button
              variant="secondary"
              className="ml-auto"
              onClick={() => handleSelect(device)}
            >
              {t("select")}
            </Button>
          </div>
        ))}
      </div>
      <DialogFooter>
        <Button variant="outline" onClick={handleCancel}>
          {t("cancel")}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
