import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { DialogFooter } from "@/components/ui/dialog";
import { FormEvent } from "react";
import { useWizard } from "react-use-wizard";
import { useMutateCreatePartner } from "@/lib/hooks/query/partner/useMutateCreatePartner";

interface CreatePartnerFooterProps {
  onBack: () => void;
  onNext: (event: FormEvent<HTMLElement>) => void;
  nextDisabled?: boolean;
}

export function CreatePartnerFooter({
  onBack,
  onNext,
  nextDisabled,
}: Readonly<CreatePartnerFooterProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-partner",
  });
  const { isFirstStep, isLastStep } = useWizard();
  const createPartnerMutation = useMutateCreatePartner();

  return (
    <DialogFooter className="mt-6 grid grid-cols-2 gap-5">
      <Button type="button" variant="secondary" onClick={onBack}>
        {isFirstStep ? t("cancel") : t("back")}
      </Button>
      <Button
        type="submit"
        disabled={nextDisabled || createPartnerMutation.isPending}
        onClick={onNext}
      >
        {isLastStep ? t("create") : t("continue")}
      </Button>
    </DialogFooter>
  );
}
