import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import * as z from "zod";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldPath, useForm, useFormContext } from "react-hook-form";
import { FormEvent } from "react";
import { useMutateCreateProductCode } from "@/lib/hooks/query/productCode/useMutateCreateProductCode";

const FormSchema = z.object({
  materialCode: z.string().min(1).max(100),
  productName: z.string().min(1).max(100),
  description: z.string().min(1).max(100),
});

type FormValues = z.infer<typeof FormSchema>;

export function CreateProductCodeModal({
  endCustomerId,
}: {
  endCustomerId: number;
}) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-product-code",
  });
  const { hideModal } = useModal();

  const createProductCode = useMutateCreateProductCode();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      materialCode: "",
      productName: "",
      description: "",
    },
  });

  const handleCancel = () => {
    hideModal();
  };

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const values = form.getValues();
    await createProductCode.mutateAsync({
      materialCode: values.materialCode,
      productName: values.productName,
      description: values.description,
      endCustomerId: endCustomerId,
    });
    hideModal();
  };

  const isSubmitDisabled =
    !form.formState.isValid || createProductCode.isPending;

  return (
    <DialogContent className="sm:max-w-md">
      <Form {...form}>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <DialogHeader className="flex flex-col items-center">
            <DialogTitle>{t("create-product-code")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <TextInput name="materialCode" label={t("material-code")} />
            <TextInput name="productName" label={t("product-name")} />
            <TextInput name="description" label={t("description")} />
          </div>
          <DialogFooter className="mt-6 grid grid-cols-2 gap-5">
            <Button type="button" variant="secondary" onClick={handleCancel}>
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            >
              {t("create")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}

function TextInput({
  name,
  label,
}: Readonly<{ name: FieldPath<FormValues>; label: string }>) {
  const form = useFormContext<FormValues>();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="h-20 w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              ref={field.ref}
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value as string}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
