import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { apiClient } from "@/api";
import { Partner } from "@/api/generated/data-contracts";

type QueryKey = ["partners"];

export function useQueryPartners<TData = Partner[]>(
  options?: Omit<
    UseQueryOptions<Partner[], unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<Partner[], unknown, TData, QueryKey>({
    staleTime: 1000 * 60 * 10, // 10 minutes
    ...options,
    queryKey: ["partners"],
    queryFn: async () => {
      const response = await apiClient.partnersList();
      return Object.values(response.data);
    },
  });
}
