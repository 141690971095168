import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogHeader,
  DialogDescription,
} from "@/components/ui/dialog";
import * as z from "zod";
import { useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useQueryOrderById } from "@/lib/hooks/query/order/useQueryOrderById";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "@uidotdev/usehooks";
import { AlertOctagon, CheckIcon } from "lucide-react";
import { OrderV2 } from "@/api/generated/data-contracts";
import { Label } from "@radix-ui/react-label";
import { useStateIdTranslation } from "@/lib/hooks/useStateIdTranslation";
import { dateToLocaleDate } from "@/lib/dateUtils";
import { useModal } from "@/lib/modals/useModal";

const FormSchema = z.object({
  orderId: z.string().min(1).regex(/^\d+$/),
});
type FormValues = z.infer<typeof FormSchema>;

interface MissingOrderStepProps {
  serialNumber?: string;
  setOrderId: (orderId: number) => void;
  onCancel?: () => void;
}

export function MissingOrderStep({
  setOrderId,
  onCancel,
  serialNumber,
}: Readonly<MissingOrderStepProps>) {
  const { t } = useTranslation("", { keyPrefix: "inbound.modal.add-device" });

  const { hideModal } = useModal();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      orderId: "",
    },
  });

  const orderId = form.watch("orderId");
  const debouncedOrderId = useDebounce(orderId, 500);
  const orderQuery = useQueryOrderById(parseInt(debouncedOrderId), {
    retry: 0,
  });

  const handleSubmit = (values: FormValues) => {
    setOrderId(parseInt(values.orderId));
  };

  const handleCancel = () => {
    onCancel?.();
    hideModal();
  };

  return (
    <DialogContent className="sm:max-w-md">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex flex-col gap-6"
        >
          <DialogHeader className="flex flex-col items-center">
            <AlertOctagon className="size-12" />
            <DialogTitle>{t("device-is-not-part-of-any-order")}</DialogTitle>
            <DialogDescription className="flex h-4 flex-col items-center">
              SN: {serialNumber}
            </DialogDescription>
            <DialogDescription className="flex h-4 flex-col items-center">
              {t("select-order-you-want-to-add-device-to")}
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <OrderIdField />
            <OrderCard order={orderQuery.data} />
          </div>

          <DialogFooter className="grid grid-cols-2">
            <Button
              data-testid="cancel-button"
              variant="secondary"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              data-testid="select-button"
              type="submit"
              disabled={!orderQuery.isSuccess}
            >
              {t("select")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}

function OrderCard({ order }: Readonly<{ order: OrderV2 | undefined }>) {
  const stateTranslation = useStateIdTranslation();

  const state = order?.orderStateId && stateTranslation(order?.orderStateId);
  const createdDate =
    order?.orderCreateDate && dateToLocaleDate(order?.orderCreateDate);
  return (
    <div className="flex flex-col gap-3 rounded bg-[#F8F8F8] p-3">
      <OrderCardItem label="Partner" value={order?.partnerName} />
      <OrderCardItem label="Customer" value={order?.endCustomerName} />
      <OrderCardItem label="State" value={state} />
      <OrderCardItem label="Created" value={createdDate} />
    </div>
  );
}

function OrderCardItem({
  label,
  value,
}: Readonly<{ label: string; value?: string | number }>) {
  return (
    <Label className="flex gap-4">
      <span>{label}:</span>
      <span>{value ?? "-"}</span>
    </Label>
  );
}

function OrderIdField() {
  const form = useFormContext<FormValues>();
  const orderId = form.watch("orderId");
  const debouncedOrderId = useDebounce(orderId, 500);
  const orderQuery = useQueryOrderById(parseInt(debouncedOrderId), {
    enabled: !!debouncedOrderId,
    retry: 0,
  });

  return (
    <FormField
      control={form.control}
      name="orderId"
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>Select order</FormLabel>
          <FormControl>
            <div className="relative flex items-center gap-2">
              <Input type="number" data-testid="orderId-input" {...field} />
              {orderQuery.isSuccess && (
                <CheckIcon
                  className={"absolute right-2 size-8 text-green-500"}
                />
              )}
              {orderQuery.isError && (
                <XMarkIcon className={"absolute right-2 size-8 text-red-500"} />
              )}
              {orderQuery.isPending && !!orderId && (
                <ArrowPathIcon
                  className={"absolute right-2 size-8 animate-spin"}
                />
              )}
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
