import { useAtomValue } from "jotai";
import { DialogContent } from "@/components/ui/dialog";
import { ScannedDevice, targetOrderIdAtom } from "@/views/inbound/inboundState";
import { useQueryOrderById } from "@/lib/hooks/query/order/useQueryOrderById";
import { MissingOrderStep } from "./steps/MissingOrderStep";
import { useQueryEndCustomerById } from "@/lib/hooks/query/endcustomer/useQueryPartnerById";
import { MissingTagStep } from "@/lib/modals/inbound/AddDeviceModal/steps/MissingTagStep";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { DeviceFormStep } from "@/lib/modals/inbound/AddDeviceModal/steps/DeviceFormStep";
import { useState } from "react";

export interface InboundAddDeviceModalProps {
  device?: ScannedDevice;
  onConfirm?: (device: ScannedDevice) => void;
  onCancel?: () => void;
}

export function InboundAddDeviceModal({
  device,
  onConfirm,
  onCancel,
}: Readonly<InboundAddDeviceModalProps>) {
  const targetOrderId = useAtomValue(targetOrderIdAtom);

  const [selectedOrder, setSelectedOrder] = useState(targetOrderId);

  const orderQuery = useQueryOrderById(selectedOrder);
  const endCustomerQuery = useQueryEndCustomerById(
    orderQuery.data?.endCustomerId,
  );

  const isOrderSelected = !!selectedOrder;

  if (orderQuery.isLoading || endCustomerQuery.isLoading) {
    return (
      <DialogContent className="sm:max-w-md">
        <LoadingSpinner />
      </DialogContent>
    );
  }

  const isTagSet = !!endCustomerQuery.data?.tag;

  if (!isOrderSelected) {
    return (
      <MissingOrderStep
        setOrderId={setSelectedOrder}
        onCancel={onCancel}
        serialNumber={device?.serialNumber}
      />
    );
  }

  if (!isTagSet) {
    return (
      <MissingTagStep endCustomer={endCustomerQuery.data} onCancel={onCancel} />
    );
  }

  const newDevice: ScannedDevice = {
    ...device,
    orderId: selectedOrder,
    serialNumber: device?.serialNumber ?? "",
    state: "missing",
    isPrinted: false,
    isExtra: false,
  };

  return (
    <DeviceFormStep
      device={newDevice}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
}
