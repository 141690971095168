import { useAtom } from "jotai";

import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  ScannedDevice,
  scannedDevicesAtom,
  targetOrderIdAtom,
} from "@/views/inbound/inboundState";
import { useTranslation } from "react-i18next";
import { useModal } from "@/lib/modals/useModal";

export interface InboundRemoveDeviceFromCheckInModalProps {
  device: ScannedDevice;
}

export function InboundRemoveDeviceFromCheckInModal({
  device,
}: Readonly<InboundRemoveDeviceFromCheckInModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.remove-device",
  });
  const { hideModal } = useModal();

  const [, setScannedDevices] = useAtom(scannedDevicesAtom);
  const [, setTargetOrderId] = useAtom(targetOrderIdAtom);

  const handleSubmit = () => {
    // TODO Change to use api
    setScannedDevices((oldDevices) => {
      const newDevices = oldDevices.filter((d) => d !== device);
      if (newDevices.length === 0) {
        setTargetOrderId(undefined);
      }
      return newDevices;
    });
    hideModal();
  };

  return (
    <DialogContent className="flex flex-col gap-6 sm:max-w-md">
      <DialogTitle>{t("remove-device-from-inbound")}</DialogTitle>
      <div className="flex flex-col text-[#667085]">
        <span className="font-medium">
          {t("are-you-sure-you-dont-want-to-inbound-device")}
        </span>
        <span className="font-bold">{device.eolTrackingNumber}</span>
      </div>
      <div className="mb-4 rounded-lg border-2 border-destructive bg-destructive/5 p-6">
        <span>
          {t("if-you-created-a-label-for-the-device-remove-it-and-destroy-it")}
        </span>
      </div>
      <DialogFooter className="grid grid-cols-2 gap-2">
        <Button variant="secondary" onClick={hideModal}>
          {t("cancel")}
        </Button>
        <Button onClick={handleSubmit}>{t("remove-device")}</Button>
      </DialogFooter>
    </DialogContent>
  );
}
