import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { Asset, EOLBatchItem } from "@/api/generated/data-contracts";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";

type MutationError = HttpResponse<Asset, Error>;

interface MutationVariables {
  device: EOLBatchItem;
}

export function useMutationUpdateDeviceOld(
  options?: Omit<
    UseMutationOptions<void, MutationError, MutationVariables>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<void, MutationError, MutationVariables>({
    ...options,
    mutationFn: async ({ device }) => {
      await apiClient.assetsUpdate([device]);
      queryClient.invalidateQueries({ queryKey: ["devices"] });
      queryClient.invalidateQueries({ queryKey: ["device-stage-changes"] });
    },
  });
}
