import * as z from "zod";
import { FieldPath, useForm, useFormContext } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useQueryDeviceCategories } from "@/lib/hooks/query/device/useQueryDeviceCategories";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScannedDevice } from "@/views/inbound/inboundState";
import { useModal } from "@/lib/modals/useModal";

export const FormSchema = z.object({
  categoryId: z.string().min(1).regex(/^\d+$/),
  model: z.string(),
  manufacturer: z.string(),
  serialNumber: z.string(),
  extraItems: z.string(),
});

type FormValues = z.infer<typeof FormSchema>;

export interface InboundAddDeviceModalProps {
  device: ScannedDevice;
  onConfirm?: (device: ScannedDevice) => void;
  onCancel?: () => void;
}

export function DeviceFormStep({
  device,
  onConfirm,
  onCancel,
}: Readonly<InboundAddDeviceModalProps>) {
  const { t } = useTranslation("", { keyPrefix: "inbound.modal.add-device" });
  const { hideModal } = useModal();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      categoryId: device.categoryId?.toString() ?? "",
      manufacturer: device.manufacturer ?? "",
      model: device.model ?? "",
      serialNumber: device.serialNumber ?? "",
      extraItems: device.extraItems ?? "",
    },
  });

  const handleSubmitLocal = (values: FormValues) => {
    const newDevice: ScannedDevice = {
      ...values,
      orderId: device.orderId,
      categoryId: parseInt(values.categoryId),
      state: "loading",
      isPrinted: false,
      isExtra: false,
    };
    onConfirm?.(newDevice);
    hideModal();
  };

  const handleCancel = () => {
    onCancel?.();
    hideModal();
  };

  return (
    <DialogContent className="sm:max-w-md">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmitLocal)}
          className="flex flex-col gap-6"
        >
          <DialogHeader>
            <DialogTitle>{t("sign-device-in-manually")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-6">
            <DeviceCategorySelect orderId={device.orderId!} />
            <TextField label={t("brand")} name="manufacturer" />
            <TextField label={t("model")} name="model" />
            <TextField
              label={t("read-serial-number")}
              name="serialNumber"
              autoComplete="off"
            />
            <TextField
              label={t("additional-information-optional")}
              name="extraItems"
            />
          </div>
          <DialogFooter className="sm:justify-start">
            <Button
              className="w-full"
              variant="secondary"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              className="w-full"
              disabled={!form.formState.isValid}
            >
              {t("add-device")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}

function TextField<
  TName extends FieldPath<FormValues> = FieldPath<FormValues>,
>({
  label,
  name,
  autoComplete,
}: Readonly<{
  label: string;
  name: TName;
  autoComplete?: string;
}>) {
  const form = useFormContext<FormValues>();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input {...field} autoComplete={autoComplete} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export const DeviceCategorySelect = ({ orderId }: { orderId: number }) => {
  const { t } = useTranslation("", { keyPrefix: "inbound.modal.add-device" });

  const form = useFormContext<FormValues>();

  const { data, isPending } = useQueryDeviceCategories(orderId, {
    select: (data) =>
      data.map((item) => ({ id: item.id.toString(), name: item.name })) ?? [],
  });

  let deviceCategoryPlaceholder = t("select-device-category");
  if (isPending) deviceCategoryPlaceholder = "Loading...";
  if (data?.length === 0)
    deviceCategoryPlaceholder = t("missing-device-categories-for-this-order");

  return (
    <FormField
      control={form.control}
      name="categoryId"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t("device-category")}</FormLabel>
          <Select
            data-testid="printer-select"
            onValueChange={field.onChange}
            disabled={!orderId || data?.length === 0}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={deviceCategoryPlaceholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent data-testid="printer-select">
              {data?.map((deviceCategory) => (
                <SelectItem
                  key={deviceCategory.id}
                  value={deviceCategory.id.toString()}
                >
                  {deviceCategory.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
