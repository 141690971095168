import { useAtom, useAtomValue } from "jotai";
import {
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  ScannedDevice,
  scannedDevicesAtom,
  targetOrderIdAtom,
} from "@/views/inbound/inboundState";
import { Button } from "@/components/ui/button";
import { useMutationCheckInDevice } from "@/lib/hooks/query/device/useMutationCheckInDevice";
import { useTranslation } from "react-i18next";
import { useModal } from "@/lib/modals/useModal";

export function InboundAddExtraDeviceFromOtherOrderModal({
  device,
}: Readonly<{ device: ScannedDevice }>) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.add-from-other-order",
  });

  const { hideModal } = useModal();

  const targetOrderId = useAtomValue(targetOrderIdAtom);
  const [scannedDevices, setScannedDevices] = useAtom(scannedDevicesAtom);

  const checkInMutation = useMutationCheckInDevice();

  const handleRemove = () => {
    setScannedDevices(scannedDevices.filter((d) => d !== device));
    hideModal();
  };

  const handleAdd = async () => {
    device.id = undefined;
    device.orderId = targetOrderId;
    const newDevice = await checkInMutation.mutateAsync({ device });
    device.state = "success";
    device.eolTrackingNumber = newDevice.eolTrackingNumber!;
    setScannedDevices((oldDevices) => [...oldDevices]);
    hideModal();
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogTitle>{t("device-belongs-to-a-different-order")}</DialogTitle>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Item
            label={t("device-belongs-to-order")}
            value={device.orderId?.toString() ?? "-"}
          />
          <Item
            label={t("you-are-currently-inbounding-order")}
            value={targetOrderId?.toString() ?? "-"}
          />
        </div>
        <Item
          label={t("are-you-sure-you-want-to-add-device-to-order")}
          value={t("targetorderid-as-an-extra-device", {
            targetOrderId: targetOrderId?.toString() ?? "-",
          })}
        />
      </div>
      <DialogFooter className="grid grid-cols-2 gap-2">
        <Button
          disabled={checkInMutation.isPending}
          variant="outline"
          onClick={handleAdd}
        >
          {t("add-device")}
        </Button>
        <Button disabled={checkInMutation.isPending} onClick={handleRemove}>
          {t("remove")}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}

function Item({ label, value }: Readonly<{ label: string; value: string }>) {
  return (
    <div className="flex flex-col text-[#667085]">
      <span className="font-medium">{label}</span>
      <span className="font-bold">{value}</span>
    </div>
  );
}
