/* eslint-disable camelcase */
import { JSPrintManager, WSStatus } from "jsprintmanager";
import { useCallback, useEffect, useId, useState } from "react";
import { ENV } from "@/env";

const printerHooks = new Set<string>();

if (ENV.environment !== "staging") {
  JSPrintManager.license_url = `${ENV.apiUrl}/api/JSPM`;
}

JSPrintManager.auto_reconnect = true;

export function usePrinter() {
  const id = useId();

  const [isPrinterReady, setIsPrinterReady] = useState(
    JSPrintManager.websocket_status === WSStatus.Open,
  );

  const startPrinter = useCallback(async () => {
    printerHooks.add(id);
    if (JSPrintManager.websocket_status === WSStatus.Open) return;
    if (printerHooks.size > 1) return;
    try {
      await JSPrintManager.start(
        !ENV.printer.mock,
        undefined,
        ENV.printer.mock ? 9999 : undefined,
      );
    } catch (error) {
      printerHooks.delete(id);
      console.error("JSPrintManager.start error", error);
    }
  }, [id]);

  const stopPrinter = useCallback(async () => {
    if (!printerHooks.has(id)) return;
    printerHooks.delete(id);
    setTimeout(async () => {
      if (printerHooks.size !== 0) return;
      if (JSPrintManager.websocket_status !== WSStatus.Open) return;
      JSPrintManager.stop();
    }, 1000);
  }, [id]);

  useEffect(() => {
    startPrinter();
    return () => {
      stopPrinter();
    };
  }, [startPrinter, stopPrinter]);

  useEffect(() => {
    const interval = setInterval(() => {
      const isReady = JSPrintManager.websocket_status === WSStatus.Open;
      if (!isReady) return;
      setIsPrinterReady(isReady);
      clearInterval(interval);
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    isPrinterReady,
  };
}
