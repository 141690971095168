import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import * as z from "zod";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormEvent } from "react";

const FormSchema = z.object({
  trackingNumber: z.string().min(1),
});
type FormValues = z.infer<typeof FormSchema>;

export interface ManualTrackingNumberModalProps {
  onSubmit?: (trackingNumber: string) => void;
  onCancel?: () => void;
}

export function ManualTrackingNumberModal({
  onSubmit,
  onCancel,
}: Readonly<ManualTrackingNumberModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.manual-tracking-number",
  });
  const { hideModal } = useModal();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      trackingNumber: "",
    },
  });

  const handleCancel = () => {
    onCancel?.();
    hideModal();
  };

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const values = form.getValues();
    onSubmit?.(values.trackingNumber);
    hideModal();
  };

  return (
    <DialogContent className="sm:max-w-md">
      <Form {...form}>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <DialogHeader className="flex flex-col items-center">
            <DialogTitle>{t("manual-tracking-number-input")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="trackingNumber"
              render={({ field }) => (
                <FormItem className="h-20 w-full">
                  <FormLabel>{t("tracking-number")}</FormLabel>
                  <FormControl>
                    <Input {...field} autoComplete="off" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <DialogFooter className="mt-6 grid grid-cols-2 gap-5">
            <Button type="button" variant="secondary" onClick={handleCancel}>
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              disabled={!form.formState.isValid}
              onClick={handleSubmit}
            >
              {t("submit")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}
