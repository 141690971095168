import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useRef } from "react";

interface BufferCharacter {
  time: number;
  char: string;
}

interface config {
  /** Time to wait from last character to then trigger an evaluation of the buffer. */
  timeToEvaluate?: number;
  /** Average time between characters in milliseconds. Used to determine if input is from keyboard or a scanner. Defaults to 50ms.*/
  averageWaitTime?: number;
  /** Character that barcode scanner prefixes input with.*/
  startCharacter?: Array<string>;
  /** Character that barcode scanner suffixes input with. Defaults to line return.*/
  endCharacter?: Array<string>;
  /** Callback to use on complete scan input.*/
  onComplete: (code: string) => void;
  /** Callback to use on error. */
  onError?: (error: string) => void;
  /** Minimum length a scanned code should be. Defaults to 0.*/
  minLength?: number;
  /** Ignore scan input if this node is focused.*/
  ignoreIfFocusOn?: Node;
  /** Stop propagation on keydown event. Defaults to false.*/
  stopPropagation?: boolean;
  /** Prevent default on keydown event. Defaults to false.*/
  preventDefault?: boolean;
  /** Bind keydown event to this node. Defaults to document.*/
  container?: Node;
}

const isScannerEnabledAtom = atom(true);

const FILTER_REGEX = /^&(.*?)&$/;

export const useScannerController = () => {
  const [isScannerEnabled, setScannerEnabled] = useAtom(isScannerEnabledAtom);
  return {
    isScannerEnabled,
    setScannerEnabled,
    enableScanner: () => setScannerEnabled(true),
    disableScanner: () => setScannerEnabled(false),
    toggleScanner: () => setScannerEnabled((prev) => !prev),
  };
};

/**
 * Checks for scan input within a container and sends the output to a callback function.
 * @param config Config object
 */
export const useScanDetection = ({
  timeToEvaluate = 100,
  averageWaitTime = 10,
  startCharacter = [],
  endCharacter = [],
  onComplete,
  onError,
  minLength = 1,
  ignoreIfFocusOn,
  stopPropagation = false,
  preventDefault = false,
  container = document,
}: config) => {
  const buffer = useRef<BufferCharacter[]>([]);
  const timeout = useRef<NodeJS.Timeout | undefined>();

  const { isScannerEnabled } = useScannerController();

  const clearBuffer = () => {
    buffer.current = [];
  };

  const evaluateBuffer = useCallback(() => {
    if (!isScannerEnabled) return;
    clearTimeout(timeout.current);
    const sum = buffer.current
      .map(({ time }, k, arr) => (k > 0 ? time - arr[k - 1].time : 0))
      .slice(1)
      .reduce((total, delta) => total + delta, 0);
    const avg = sum / (buffer.current.length - 1);

    const code = buffer.current
      .slice(
        startCharacter.length > 0
          ? 1
          : endCharacter.length > 0
            ? -1
            : undefined,
      )
      .map(({ char }) => char)
      .join("");

    if (
      avg <= averageWaitTime &&
      buffer.current.slice(startCharacter.length > 0 ? 1 : 0).length >=
        minLength
    ) {
      onComplete(code.match(FILTER_REGEX)?.[1] ?? code);
    } else if (avg <= averageWaitTime) {
      onError?.(code);
    }
    clearBuffer();
  }, [
    averageWaitTime,
    minLength,
    onComplete,
    onError,
    startCharacter,
    endCharacter,
    isScannerEnabled,
  ]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === "Shift") return;
      if (event.ctrlKey && event.key === "Control") return;
      if (event.altKey && event.key === "Alt") return;
      if (event.metaKey && event.key === "Meta") return;

      if (stopPropagation) event.stopPropagation();
      if (preventDefault) event.preventDefault();

      if (event.currentTarget === ignoreIfFocusOn) return;

      if (endCharacter.includes(event.key)) evaluateBuffer();

      if (
        buffer.current.length > 0 ||
        startCharacter.includes(event.key) ||
        startCharacter.length === 0
      ) {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(evaluateBuffer, timeToEvaluate);
        buffer.current.push({ time: performance.now(), char: event.key });
      }
    },
    [
      startCharacter,
      endCharacter,
      timeToEvaluate,
      ignoreIfFocusOn,
      stopPropagation,
      preventDefault,
      evaluateBuffer,
    ],
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    container.addEventListener("keydown", onKeyDown as EventListener);
    return () => {
      container.removeEventListener("keydown", onKeyDown as EventListener);
    };
  }, [onKeyDown, container]);
};
