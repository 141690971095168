import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { MultiLineInput } from "@/components/ui/input";
import { FieldPath, FieldValues, useFormContext } from "react-hook-form";

export function MultilineTextInput<T extends FieldValues>({
  name,
  label,
}: Readonly<{ name: FieldPath<T>; label: string }>) {
  const form = useFormContext<T>();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="h-48 w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <MultiLineInput
              ref={field.ref}
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value as string}
              className="h-40 resize-none"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
