import { useTranslation } from "react-i18next";
import { DialogContent } from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import { Wizard } from "react-use-wizard";
import { useMutateCreatePartner } from "@/lib/hooks/query/partner/useMutateCreatePartner";
import { NewPartner } from "@/api/generated/data-contracts";
import { NewPartnerValues } from "@/lib/modals/partner/CreatePartnerModal/newPartnerSchema";
import { DisclaimerStep } from "@/lib/modals/partner/CreatePartnerModal/steps/DisclaimerStep";
import { PartnerInfoStep } from "@/lib/modals/partner/CreatePartnerModal/steps/PartnerInfoStep";
import { ThemeStep } from "@/lib/modals/partner/CreatePartnerModal/steps/ThemeStep";
import { CreatePartnerHeader } from "@/lib/modals/partner/CreatePartnerModal/components/CreatePartnerHeader";

export function CreatePartnerModal() {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-partner",
  });

  const { hideModal } = useModal();

  const createPartnerMutation = useMutateCreatePartner({});

  const [newPartner, setNewPartner] = useState<Partial<NewPartnerValues>>({});

  const handleSubmit = useCallback(
    async (newPartner: NewPartnerValues) => {
      const formatedPartner: NewPartner = {
        partnerName: newPartner.name,
        email: newPartner.email,
        address: newPartner.address,
        theme: {
          darkAccentColor: newPartner.darkColor,
          lightAccentColor: newPartner.lightColor,
          logo: newPartner.logo,
        },
      };
      await createPartnerMutation
        .mutateAsync(formatedPartner)
        .then(() => {
          toast.success(t("partner-created", { partnerName: newPartner.name }));
          hideModal();
        })
        .catch(() => {
          toast.error(t("something-went-wrong-when-creating-partner"));
        });
    },
    [createPartnerMutation, t, hideModal],
  );

  return (
    <DialogContent className="sm:max-w-md">
      <Wizard header={<CreatePartnerHeader />}>
        <PartnerInfoStep
          newPartner={newPartner}
          setNewPartner={setNewPartner}
        />
        <DisclaimerStep newPartner={newPartner} setNewPartner={setNewPartner} />
        <ThemeStep
          newPartner={newPartner}
          setNewPartner={setNewPartner}
          onSubmit={handleSubmit}
        />
      </Wizard>
    </DialogContent>
  );
}
