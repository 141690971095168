import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import { useInboundLabelPrinter } from "@/lib/hooks/printing/labels/useInboundLabelPrinter";
import { useQueryDeviceById } from "@/lib/hooks/query/device/useQueryDeviceById";
import { useQueryEndCustomerById } from "@/lib/hooks/query/endcustomer/useQueryPartnerById";
import { useQueryOrderById } from "@/lib/hooks/query/order/useQueryOrderById";
import { toast } from "sonner";
import { PrinterSelect } from "@/components/PrinterSelect";
import { usePrinter } from "@/lib/hooks/printing/usePrinter";

export interface ErrorModalProps {
  deviceId: number;
}

export function InboundPrintModal({ deviceId }: Readonly<ErrorModalProps>) {
  const { t } = useTranslation("", { keyPrefix: "common.modal.inbound-print" });
  const { hideModal } = useModal();

  const { isPrinterReady } = usePrinter();
  const { printLabel } = useInboundLabelPrinter();

  const deviceQuery = useQueryDeviceById(deviceId);
  const orderQuery = useQueryOrderById(deviceQuery.data?.orderId);
  const endCustomerQuery = useQueryEndCustomerById(
    orderQuery.data?.endCustomerId,
  );

  const handlePrint = async () => {
    const device = deviceQuery.data;
    const order = orderQuery.data;
    const endCustomer = endCustomerQuery.data;
    if (!device || !order || !endCustomer) {
      toast.error(t("could-not-print-label"));
      return;
    }
    await printLabel([
      {
        eolTrackingNumber: device.eolTrackingNumber!,
        orderId: order.id,
        serialNumber: device.readSerialNumber!,
        tag: endCustomer.tag!,
      },
    ]);
    hideModal();
  };

  const handleCancel = () => {
    hideModal();
  };

  return (
    <DialogContent className="max-w-md">
      <DialogHeader className="flex flex-col items-center">
        <DialogTitle>{t("print-afi-label")}</DialogTitle>
      </DialogHeader>
      <ul className="my-6 w-full truncate">
        <li className="truncate">
          <b>{t("order-number")}:</b> {orderQuery.data?.id}
        </li>
        <li className="truncate">
          <b>{t("end-customer-tag")}:</b> {endCustomerQuery.data?.tag}
        </li>
        <li className="truncate">
          <b>{t("tracking-number")}:</b> {deviceQuery.data?.eolTrackingNumber}
        </li>
        <li className="truncate">
          <b>{t("serial-number")}:</b>{" "}
          {deviceQuery.data?.readSerialNumber ?? deviceQuery.data?.serialNumber}
        </li>
      </ul>
      <PrinterSelect />
      {/* <DialogDescription className="flex h-4 flex-col items-center">{subtitle}</DialogDescription> */}
      <DialogFooter className="mt-6 grid grid-cols-2 gap-5">
        <Button variant="secondary" onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button
          className="w-full"
          onClick={handlePrint}
          disabled={!isPrinterReady}
        >
          {t("print")}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
