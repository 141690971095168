import { cn } from "@/lib/utils";
import { ButtonHTMLAttributes, forwardRef } from "react";

export const CardBase = forwardRef<
  HTMLDivElement,
  ButtonHTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "flex flex-col rounded-lg bg-[#F8F8F8] p-6 shadow",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});
CardBase.displayName = "Card";
