import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogHeader,
} from "@/components/ui/dialog";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { EndCustomer } from "@/api/generated/data-contracts";
import { Label } from "@radix-ui/react-label";
import { useModal } from "@/lib/modals/useModal";
import { useQueryEndCustomerById } from "@/lib/hooks/query/endcustomer/useQueryPartnerById";
import { useMutateEndCustomerTag } from "@/lib/hooks/query/endcustomer/useMutateEndCustomerTag";
import { ENV } from "@/env";

const FormSchema = z.object({
  tag: z
    .string()
    .min(2, "The tag must be at least 2 characters long")
    .max(3, "The tag must be at most 3 characters long")
    .regex(
      /^[a-zA-Z0-9-]{2,3}$/,
      "The tag must contain only letters, numbers and hyphens",
    ),
});

type FormValues = z.infer<typeof FormSchema>;

interface MissingTagStepProps {
  endCustomer: EndCustomer | undefined;
  onCancel?: () => void;
}

export function MissingTagStep({
  endCustomer,
  onCancel,
}: Readonly<MissingTagStepProps>) {
  const { t } = useTranslation("", { keyPrefix: "inbound.modal.add-device" });

  const endCustomerQuery = useQueryEndCustomerById(endCustomer?.id);

  const mutateTag = useMutateEndCustomerTag();

  const { hideModal } = useModal();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      tag: "",
    },
  });

  const handleSubmit = async (values: FormValues) => {
    if (!endCustomer) throw new Error("End customer is not defined");
    await mutateTag.mutateAsync({
      endCustomerId: endCustomer.id!,
      tag: values.tag,
    });
    endCustomerQuery.refetch();
  };

  const handleCancel = () => {
    onCancel?.();
    hideModal();
  };

  const linkUrl =
    ENV.portalUrl + "/partners/" + endCustomer?.partner?.id + "/customers";

  return (
    <DialogContent className="sm:max-w-md">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex flex-col gap-6"
        >
          <DialogHeader className="flex flex-col items-center">
            <DialogTitle>Missing Tag</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col items-center gap-3 text-center">
            <span>{t("description-text")}</span>
            <span>
              {t("portal-description-text-start")}{" "}
              <a href={linkUrl}>[{t("link")}]</a>{" "}
              {t("portal-description-text-end")}
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <Label className="flex gap-2">
              <span>{t("end-customer-name")}:</span>
              <span className="font-bold text-gray-500">
                {endCustomer?.name}
              </span>
            </Label>
            <FormField
              control={form.control}
              name="tag"
              render={({ field }) => (
                <FormItem className="h-20 w-full">
                  <FormLabel>{t("end-customer-tag")}</FormLabel>
                  <FormControl>
                    <Input data-testid="tag-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <DialogFooter className="grid grid-cols-2">
            <Button variant="secondary" onClick={handleCancel}>
              {t("cancel")}
            </Button>
            <Button type="submit">Save</Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}
