import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import { DialogDescription } from "@radix-ui/react-dialog";
import { AlertOctagon } from "lucide-react";

export interface ErrorModalProps {
  title: string;
  subtitle?: string;
  onContinue?: () => void;
}

export function ErrorModal({
  title,
  subtitle,
  onContinue,
}: Readonly<ErrorModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.component.error-modal",
  });
  const { hideModal } = useModal();

  const handleContinue = () => {
    onContinue?.();
    hideModal();
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogHeader className="flex flex-col items-center">
        <AlertOctagon className="size-12" />
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <DialogDescription className="flex h-4 flex-col items-center">
        {subtitle}
      </DialogDescription>
      <DialogFooter className="sm:justify-start">
        <Button className="w-full" onClick={handleContinue} shortcutKey="ENTER">
          {t("ok")}&nbsp;<span className="text-[#1B261B]/50">[ENTER]</span>
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
