import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import { ModalProps, ModalType, modalStateAtom } from "./modalState";
import { useCallback } from "react";

export function useModal() {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const showModal = useCallback(
    <T extends ModalType>(
      modalType: T,
      modalProps: ModalProps<T> extends never ? undefined : ModalProps<T>,
    ) => {
      return new Promise<void>((resolve) => {
        setModalState({
          modalType,
          modalProps,
          modalResolve: resolve,
        });
      });
    },
    [setModalState],
  );

  const hideModal = useCallback(() => {
    setModalState(RESET);
    modalState.modalResolve?.();
  }, [modalState, setModalState]);

  return {
    showModal,
    hideModal,
    modalState,
  };
}
