import { DialogContent } from "@/components/ui/dialog";
import { MissingAssets } from "@/api/generated/data-contracts";
import { Button } from "@/components/ui/button";
import { useModal } from "@/lib/modals/useModal";
import { useTranslation } from "react-i18next";
import { useMutationOrderMissingDevices } from "@/lib/hooks/query/order/useMutateOrderMissingDevices";
import { LoadingSpinner } from "@/components/LoadingSpinner";

export interface InboundMissingDevicesModalProps {
  orderId: number;
  missingDevices: MissingAssets;
}

export function InspectMissingDevicesModal({
  orderId,
  missingDevices,
}: Readonly<InboundMissingDevicesModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.missing-devices",
  });
  const { hideModal } = useModal();

  const mutationMissingDevices = useMutationOrderMissingDevices();

  const handleMarkMissing = async () => {
    await mutationMissingDevices.mutateAsync({ orderId });
    hideModal();
  };

  const handleKeepWaiting = () => {
    hideModal();
  };

  const totalDevicesCount = missingDevices.orderTotalAssets ?? 0;
  const inboundDevicesCount = missingDevices.orderCheckedInAssets ?? 0;
  const missingDevicesCount = totalDevicesCount - inboundDevicesCount;

  const isPending = mutationMissingDevices.isPending;

  return (
    <DialogContent className="flex flex-col gap-14 sm:max-w-md">
      <h2 className="text-lg font-semibold">
        {t("devices-missing-from-order")}
      </h2>
      <div className="flex flex-col gap-4">
        <span>
          {t("devices-were-inbound-for-order", {
            count: inboundDevicesCount,
            total: totalDevicesCount,
          })}{" "}
          <b>{orderId}</b>.
        </span>
        <span>
          {t("what-is-the-status-of-the-count-missing-devices", {
            count: missingDevicesCount,
          })}
        </span>
      </div>
      <div className="flex w-full flex-col gap-2">
        <Button onClick={handleMarkMissing} disabled={isPending}>
          {isPending && <LoadingSpinner className="mr-3 size-5" />}
          {t("mark-devices-as-missing")}
        </Button>
        <Button
          variant="secondary"
          onClick={handleKeepWaiting}
          disabled={isPending}
        >
          {t("keep-devices-as-waiting")}
        </Button>
      </div>
    </DialogContent>
  );
}
