import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import * as z from "zod";
import {
  addTextToPage,
  formatCode39Data,
  useFont,
} from "@/lib/hooks/printing/labels/utils";
import helveticaSrc from "@/assets/Helvetica.ttf";
import barcode39Src from "@/assets/LibreBarcode39-Regular.ttf";
import { usePrint } from "@/lib/hooks/printing/usePrint";
import { useMutation } from "@tanstack/react-query";
import { dateToLocaleDate } from "@/lib/dateUtils";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useMutationOrderById } from "@/lib/hooks/query/order/useMutateOrderById";

// Currently labels are printed with batchId instead of orderId
// Function replaces orderId with batchId in label data

// widthInPoints = (100mm / 25.4mm) * 72pt
const widthInPoints = (100 / 25.4) * 72;

// heightInPoints = (15mm / 25.4mm) * 72pt
const heightInPoints = (15 / 25.4) * 72;

const inboundLabelDataSchema = z.array(
  z.object({
    tag: z
      .string()
      .min(1)
      .max(3)
      .regex(/^[a-zA-Z0-9-]{2,3}$/),
    orderId: z.number(),
    serialNumber: z.string(),
    eolTrackingNumber: z.string(),
  }),
);

type InboundLabelData = z.infer<typeof inboundLabelDataSchema>;

export function useInboundLabelPrinter() {
  const { mutateAsync: print } = usePrint();

  const barcodeFontQuery = useFont(barcode39Src, "barcode39");
  const helveticaFontQuery = useFont(helveticaSrc, "helvetica");

  const orderMutation = useMutationOrderById();

  const mutation = useMutation({
    mutationFn: async (labelData: InboundLabelData) => {
      const orderCache = new Map<number, number>();
      const fixedData = await Promise.all(
        labelData.map(async (label) => {
          const { orderId } = label;
          if (orderCache.has(orderId))
            return { ...label, orderId: orderCache.get(orderId)! };
          const order = await orderMutation.mutateAsync({ orderId });
          orderCache.set(orderId, order.batchId);
          return {
            ...label,
            orderId: order.batchId,
          };
        }),
      );

      inboundLabelDataSchema.parse(fixedData);
      const barcodeFont = (await barcodeFontQuery.refetch()).data;
      const helveticaFont = (await helveticaFontQuery.refetch()).data;
      if (!barcodeFont || !helveticaFont)
        throw new Error("Failed to load fonts");
      const labelPdf = await generateInboundLabel(fixedData, {
        barcodeFont,
        helveticaFont,
      });
      await print({ file: labelPdf, paper: "Custom.100x15mm" });
    },
  });
  useLocalStorage("right-margin", 5);

  return {
    printLabel: mutation.mutateAsync,
    isPrinting: mutation.isPending,
  };
}

export async function generateInboundLabel(
  labelData: InboundLabelData,
  fontSet: FontSet,
) {
  inboundLabelDataSchema.parse(labelData);

  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit);
  const fonts = await embedFonts(pdfDoc, fontSet);

  for (const label of labelData) {
    const { orderId, serialNumber, eolTrackingNumber, tag } = label;

    const page = pdfDoc.addPage([widthInPoints, heightInPoints]);

    const pageHeight = page.getHeight();

    const dateString = dateToLocaleDate(new Date());

    const X_MARGIN = 5;

    addTextToPage(page, dateString, X_MARGIN, 1, fonts.helveticaFont, 8);

    addTextToPage(page, "S/N", X_MARGIN, 18, fonts.helveticaFont, 10);
    addTextToPage(
      page,
      serialNumber,
      X_MARGIN + 20,
      18,
      fonts.helveticaFont,
      10,
    );

    addTextToPage(
      page,
      eolTrackingNumber,
      X_MARGIN + 50,
      1,
      fonts.helveticaFont,
      10,
    );

    const xTag = 60;
    const tagWidth = fonts.helveticaFont.widthOfTextAtSize(tag, 8);

    addTextToPage(page, tag, xTag, 32, fonts.helveticaFont, 8);
    addTextToPage(
      page,
      orderId.toString(),
      xTag + tagWidth + 5,
      32,
      fonts.helveticaFont,
      8,
    );

    const xString = window.localStorage.getItem("right-margin");
    if (!xString) throw new Error("right-margin not set");
    const x = parseInt(xString);

    addTextToPage(
      page,
      formatCode39Data(eolTrackingNumber),
      120 - x,
      -15,
      fonts.barcodeFont,
      30,
    );
    addTextToPage(
      page,
      formatCode39Data(orderId.toString()),
      120,
      pageHeight - 20,
      fonts.barcodeFont,
      30,
    );

    // TODO Waiting for priority implementation
    // if (device.isPrioritized) {
    //   page.drawRectangle({
    //     x: 10,
    //     y: 1,
    //     width: helveticaFont.widthOfTextAtSize('Prioritized', 8),
    //     height: helveticaFont.heightAtSize(8) + 2,
    //     color: rgb(0, 0, 0),
    //   })
    //   page.drawCircle({
    //     x: 10,
    //     y: ((helveticaFont.heightAtSize(8) + 2) / 2) + 1,
    //     size: (helveticaFont.heightAtSize(8) + 2) / 2,
    //     color: rgb(0, 0, 0),
    //   })
    //   page.drawCircle({
    //     x: helveticaFont.widthOfTextAtSize('Prioritized', 8) + 10,
    //     y: ((helveticaFont.heightAtSize(8) + 2) / 2) + 1,
    //     size: (helveticaFont.heightAtSize(8) + 2) / 2,
    //     color: rgb(0, 0, 0),
    //   })
    //   addTextToPage(page, 'Prioritized', 9, 31.5, helveticaFont, 8, rgb(1, 1, 1))
    // }
  }

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: "application/pdf" });
}

type FontSet = {
  barcodeFont: ArrayBuffer;
  helveticaFont: ArrayBuffer;
};

async function embedFonts(pdf: PDFDocument, fonts: FontSet) {
  const barcodeFont = await pdf.embedFont(fonts.barcodeFont, { subset: true });
  const helveticaFont = await pdf.embedFont(fonts.helveticaFont, {
    subset: true,
  });
  return { barcodeFont, helveticaFont };
}
