import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useCallback } from "react";
import { ThemeStepValues } from "@/lib/modals/partner/CreatePartnerModal/newPartnerSchema";
import { PartnerLogo } from "@/components/PartnerLogo";
import { parseBinaryImageFromFile, parseSvgFromFile } from "@/lib/utils";
import { useDropzone } from "react-dropzone";

const ACCEPTED_TYPES = [
  "image/scalable-vector-graphics",
  "image/svg+xml",
  "image/svg",
  "svg+xml",
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
];
const BINARY_TYPES = ["image/png", "image/jpeg", "image/jpg", "image/gif"];

export function LogoDropZoneField() {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-partner",
  });
  const { setValue, setError, watch } = useFormContext<ThemeStepValues>();

  const logo = watch("logo");

  const onDrop = useCallback(
    (accepted: File[]) => {
      const file = accepted[0];
      if (!ACCEPTED_TYPES.includes(file.type)) {
        setError("logo", {
          type: "invalidFileType",
          message: t("invalid-file-type"),
        });
        return;
      }
      if (BINARY_TYPES.includes(file.type)) {
        parseBinaryImageFromFile(file, (imgAsBase64) => {
          setValue("logo", imgAsBase64, { shouldValidate: true });
        });
      } else {
        parseSvgFromFile(file, (svgAsString) => {
          setValue("logo", svgAsString, { shouldValidate: true });
        });
      }
    },
    [setError, setValue, t],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className="flex h-32 w-full cursor-pointer items-center justify-center rounded-lg border-2 border-dashed p-3 hover:bg-[#e4e4e4]"
    >
      <input {...getInputProps()} />
      {!logo && (
        <p className="cursor-pointer underline">{t("drop-zone-text")}</p>
      )}
      {logo && (
        <PartnerLogo logoData={logo} className="h-full w-full flex-grow" />
      )}
    </div>
  );
}
