import { useAtomValue } from "jotai";
import {
  ClientPrintJob,
  FileSourceType,
  InstalledPrinter,
  JSPrintManager,
  PrintFilePDF,
  WSStatus,
} from "jsprintmanager";
import { useMutation } from "@tanstack/react-query";
import { ENV } from "@/env";
import { selectedPrinterAtom } from "./useQueryPrinterList";

export function usePrint() {
  const selectedPrinter = useAtomValue(selectedPrinterAtom);

  return useMutation({
    mutationKey: ["print"],
    mutationFn: async ({ file, paper }: { file: Blob; paper: string }) => {
      if (ENV.printer.labelDebug === "SKIP") {
        console.debug(
          "Label skipped printing. ENV.labelDebug:",
          ENV.printer.labelDebug,
        );
        return;
      } else if (ENV.printer.labelDebug === "DEBUG") {
        window.open(URL.createObjectURL(file));
        return;
      }
      if (!selectedPrinter) throw new Error("No printer selected");
      if (JSPrintManager.websocket_status !== WSStatus.Open)
        throw new Error("Print manager not ready");
      const printer = new InstalledPrinter(selectedPrinter);

      const cpj = new ClientPrintJob();

      printer.paperName = paper;

      cpj.clientPrinter = printer;

      const labelFile = new PrintFilePDF(
        file,
        FileSourceType.BLOB,
        "label.pdf",
        1,
      );

      cpj.files.push(labelFile);

      await cpj.sendToClient();
    },
  });
}
