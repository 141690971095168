import { Button } from "@/components/ui/button";
import {
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  Command,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { ChevronDown, Check } from "lucide-react";
import { useCallback, useState } from "react";

interface PartnerSelectProps {
  value: string | undefined;
  options: { id: string; label: string }[];
  onSelect: (value: string | undefined) => void;
  filter?: (value: string, search: string) => number;

  trigger: {
    className?: string;
    placeholder: string;
  };

  command: {
    searchPlaceholder: string;
    notFound: string;
    clearLabel?: string;
  };
}

export function Combobox({
  value,
  onSelect,
  options,
  trigger,
  command,
  filter,
}: Readonly<PartnerSelectProps>) {
  const [open, setOpen] = useState(false);

  const defaultFilter = useCallback(
    (value: string, search: string) => {
      const option = options.find((option) => option.id?.toString() === value);
      if (option?.label?.toLowerCase().includes(search.toLowerCase())) return 1;
      return 0;
    },
    [options],
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          role="combobox"
          aria-expanded={open}
          aria-label={trigger.placeholder}
          className={cn(
            "flex flex-row justify-between border border-input",
            trigger.className,
          )}
        >
          {options.find((option) => option.id?.toString() === value)?.label ??
            trigger.placeholder}
          <ChevronDown
            className={cn(
              "ml-2 hidden h-4 w-4 shrink-0 opacity-50 transition-transform duration-300 lg:block",
              open && "-scale-y-100",
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command filter={filter ?? defaultFilter}>
          <CommandInput placeholder={command.searchPlaceholder} />
          <CommandList>
            <CommandEmpty>{command.notFound}</CommandEmpty>
            <CommandGroup>
              {command.clearLabel && (
                <CommandItem
                  onSelect={() => {
                    onSelect(undefined);
                    setOpen(false);
                  }}
                >
                  <span className="ml-6">{command.clearLabel}</span>
                </CommandItem>
              )}
              {options.map((option) => (
                <CommandItem
                  key={option.id}
                  value={option.id?.toString()}
                  onSelect={(value) => {
                    onSelect(value);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 size-4 flex-shrink-0",
                      value === option.id?.toString()
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  <span>{option.label}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
