import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";

interface MutationVariables {
  orderId: number;
}

export function useMutationOrderMissingDevices(
  options?: Omit<
    UseMutationOptions<void, HttpResponse<void, Error>, MutationVariables>,
    "mutationFn"
  >,
) {
  return useMutation<void, HttpResponse<void, Error>, MutationVariables>({
    ...options,
    mutationFn: async ({ orderId }) => {
      await apiClient.ordersMissingAssetsCreate(orderId);
    },
  });
}
