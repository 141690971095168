import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  RedirectRequest,
} from "@azure/msal-browser";
import { ENV } from "@/env";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig: Configuration = {
  auth: {
    clientId: ENV.auth.clientId, // This is the ONLY mandatory field that you need to supply.
    authority: ENV.auth.authority, // Replace the placeholder with your tenant subdomain
    // redirectUri: '/login', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    // postLogoutRedirectUri: '/login', // Indicates the page to navigate after logout.
    // navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    tokenRenewalOffsetSeconds: 1800, // Determines the window of offset needed to renew the token before expiry
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii || !import.meta.env.DEV) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error("MSAL Error:", message);
            return;
          case LogLevel.Info:
            console.debug("MSAL Info:", message);
            return;
          // case LogLevel.Verbose:
          //   console.debug('MSAL Verbose:', message)
          //   return
          case LogLevel.Warning:
            console.debug("MSAL Warning:", message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  scopes: ["openid", "profile", `${ENV.auth.clientId}/.default`],
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
// export const silentRequest = {
//     scopes: ["openid", "profile"],
//     loginHint: "example@domain.net"
// };
