import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import { FormEvent, useMemo, useState } from "react";
import { STATE_TRANSTALION_KEYS } from "@/lib/hooks/useStateIdTranslation";
import {
  useQueryDeviceById,
  useQueryDeviceByIdOld,
} from "@/lib/hooks/query/device/useQueryDeviceById";
import { Combobox } from "@/components/Combobox";
import { useMutationUpdateDeviceOld } from "@/lib/hooks/query/device/useMutateDeviceOld";

export interface ManualTrackingNumberModalProps {
  deviceId: number;
}

export function ChangeDeviceStateModal({
  deviceId,
}: Readonly<ManualTrackingNumberModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.change-device-state",
  });
  const { t: stateT } = useTranslation("", { keyPrefix: "common.state" });
  const { hideModal } = useModal();

  const deviceQuery = useQueryDeviceById(deviceId);
  const deviceOldQuery = useQueryDeviceByIdOld(deviceId);

  const mutateDevice = useMutationUpdateDeviceOld();

  const [selectedState, setSelectedState] = useState<number | undefined>(
    deviceQuery.data?.stateId,
  );

  const multiselectStatuses = useMemo(() => {
    return Object.entries(STATE_TRANSTALION_KEYS).map(([state, key]) => ({
      id: state,
      label: `${state} - ${stateT(key)}`,
    }));
  }, [stateT]);

  const handleCancel = () => {
    hideModal();
  };

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    if (!deviceOldQuery.data || !selectedState) return;
    await mutateDevice.mutateAsync({
      device: {
        ...deviceOldQuery.data,
        stateId: selectedState,
      },
    });
    hideModal();
  };

  const isSubmitDisabled = !selectedState;

  return (
    <DialogContent className="sm:max-w-md">
      <div className="flex flex-col gap-6">
        <DialogHeader className="flex flex-col items-center">
          <DialogTitle>{t("change-device-state")}</DialogTitle>
        </DialogHeader>
        <Combobox
          value={selectedState?.toString()}
          options={multiselectStatuses}
          onSelect={(value) =>
            setSelectedState(value ? parseInt(value) : undefined)
          }
          trigger={{
            placeholder: t("filter-states"),
          }}
          command={{
            searchPlaceholder: t("search-states"),
            notFound: t("not-found"),
          }}
        />
        <DialogFooter className="mt-6 grid grid-cols-2 gap-5">
          <Button type="button" variant="secondary" onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {t("save")}
          </Button>
        </DialogFooter>
      </div>
    </DialogContent>
  );
}
