import { PropsWithChildren } from "react";
import { NavDrawer } from "@/components/NavDrawer";

export const BaseLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-dvh max-h-dvh w-dvw max-w-[100dvw]">
      <NavDrawer />
      <div className="flex w-full flex-grow-0 flex-col justify-between overflow-hidden">
        {children}
      </div>
    </div>
  );
};
