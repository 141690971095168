import { useTranslation } from "react-i18next";
import { Form } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormEvent } from "react";
import { useWizard } from "react-use-wizard";
import {
  ThemeStepValues,
  newPartnerSchema,
  themeStepSchema,
} from "@/lib/modals/partner/CreatePartnerModal/newPartnerSchema";
import { StepProps } from "@/lib/modals/partner/CreatePartnerModal/steps/types";
import { CreatePartnerFooter } from "@/lib/modals/partner/CreatePartnerModal/components/CreatePartnerFooter";
import { HexColorInput } from "@/components/HexColorInput";
import { LogoDropZoneField } from "@/components/LogoDropZoneField";

export function ThemeStep({
  newPartner,
  setNewPartner,
  onSubmit,
}: Readonly<StepProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-partner",
  });

  const { nextStep, previousStep } = useWizard();

  const form = useForm<ThemeStepValues>({
    resolver: zodResolver(themeStepSchema),
    mode: "onChange",
    defaultValues: {
      darkColor: "",
      lightColor: "",
      logo: "",
      ...newPartner,
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const values = form.getValues();
    setNewPartner(values);
    const newPartner = newPartnerSchema.parse(values);
    onSubmit?.(newPartner);
    nextStep();
  };

  const handleBack = () => {
    const values = form.getValues();
    setNewPartner(values);
    previousStep();
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <HexColorInput label={t("dark-theme-color")} name="darkColor" />
          <HexColorInput label={t("light-theme-color")} name="lightColor" />
          <LogoDropZoneField />
        </div>
        <CreatePartnerFooter
          onBack={handleBack}
          onNext={handleSubmit}
          nextDisabled={!form.formState.isValid}
        />
      </form>
    </Form>
  );
}
