import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { OrderV2 } from "../../../../api/generated/data-contracts";
import { apiClient } from "@/api";

type QueryFnData = OrderV2;
type QueryKey = ["orders", number | undefined];

export function useQueryOrderById<TData = QueryFnData>(
  orderId: number | undefined,
  options?: Omit<
    UseQueryOptions<QueryFnData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<QueryFnData, unknown, TData, QueryKey>({
    staleTime: 1000 * 60 * 10, // 10 minutes
    ...options,
    queryKey: ["orders", orderId],
    queryFn: async () => {
      const response = await apiClient.ordersDetail(orderId!);
      return response.data;
    },
    enabled: !!orderId && (options?.enabled ?? true),
  });
}
