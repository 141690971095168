import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";

type MutationParams = Parameters<typeof apiClient.productCodesCreate>[0];

export function useMutateCreateProductCode(
  options?: Omit<
    UseMutationOptions<void, HttpResponse<void, Error>, MutationParams>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<void, HttpResponse<void, Error>, MutationParams>({
    ...options,
    mutationFn: async (newProductCode) => {
      await apiClient.productCodesCreate(newProductCode);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productCodes"] });
    },
  });
}
