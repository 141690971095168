import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";
import { OrderV2 } from "@/api/generated/data-contracts";

interface MutationVariables {
  orderId: number;
}

export function useMutationOrderById(
  options?: Omit<
    UseMutationOptions<OrderV2, HttpResponse<void, Error>, MutationVariables>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<OrderV2, HttpResponse<void, Error>, MutationVariables>({
    ...options,
    mutationFn: async ({ orderId }) => {
      const existingOrder = queryClient.getQueryData<OrderV2 | undefined>([
        "orders",
        orderId,
      ]);
      if (existingOrder) return existingOrder;
      const response = await apiClient.ordersDetail(orderId);
      queryClient.setQueryData(["orders", orderId], response.data);
      return response.data;
    },
  });
}
