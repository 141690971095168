import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import * as z from "zod";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldPath, useForm, useFormContext } from "react-hook-form";
import { FormEvent, useRef, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  Command,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { ChevronDown, Check } from "lucide-react";
import { useQueryPartners } from "@/lib/hooks/query/partner/useQueryPartners";
import { useMutateCreateEndCustomer } from "@/lib/hooks/query/endcustomer/useMutateCreateEndCustomer";
import { toast } from "sonner";

const FormSchema = z.object({
  partnerId: z.string().min(1).regex(/^\d+$/),
  name: z.string().min(1),
  email: z.string().email(),
  address: z.object({
    street: z.string().optional(),
    postalCode: z.string().optional(),
    city: z.string().optional(),
  }),
});
type FormValues = z.infer<typeof FormSchema>;

export function CreateEndCustomerModal() {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.create-end-customer",
  });
  const { hideModal } = useModal();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      partnerId: "",
      name: "",
      email: "",
      address: {
        street: "",
        postalCode: "",
        city: "",
      },
    },
  });

  const mutateCreateEndCustomer = useMutateCreateEndCustomer();

  const handleCancel = () => {
    hideModal();
  };

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const values = form.getValues();
    try {
      await mutateCreateEndCustomer.mutateAsync({
        partnerId: parseInt(values.partnerId),
        name: values.name,
        email: values.email,
        address: values.address,
      });
      toast.success(
        t("end-customer-created", { endCustomerName: values.name }),
      );
      hideModal();
    } catch (error) {
      toast.error(t("something-went-wrong-when-creating-end-customer"));
    }
  };

  const isSubmitDisabled =
    !form.formState.isValid || mutateCreateEndCustomer.isPending;

  return (
    <DialogContent className="sm:max-w-md">
      <Form {...form}>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <DialogHeader className="flex flex-col items-center">
            <DialogTitle>{t("create-new-end-customer")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-2">
            <FormItem className="h-20 w-full">
              <FormLabel>{t("partner") + " *"}</FormLabel>
              <PartnerSelect
                value={form.watch("partnerId")}
                onSelect={(partnerId) => form.setValue("partnerId", partnerId)}
              />
              <FormMessage />
            </FormItem>
            <TextInput label={t("company-name") + " *"} name="name" />
            <TextInput label={t("email") + " *"} name="email" />
            <TextInput label={t("street")} name="address.street" />
            <TextInput label={t("postal-code")} name="address.postalCode" />
            <TextInput label={t("city")} name="address.city" />
          </div>
          <DialogFooter className="mt-6 grid grid-cols-2 gap-5">
            <Button type="button" variant="secondary" onClick={handleCancel}>
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            >
              {t("create")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}

function TextInput({
  name,
  label,
}: Readonly<{ name: FieldPath<FormValues>; label: string }>) {
  const form = useFormContext<FormValues>();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="h-20 w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              ref={field.ref}
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value as string}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

interface PartnerSelectProps {
  value: string;
  onSelect: (value: string) => void;
}

function PartnerSelect({ value, onSelect }: Readonly<PartnerSelectProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.component.partner-select",
  });
  const [open, setOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const partnersQuery = useQueryPartners();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={buttonRef}
          variant="ghost"
          size={"sm"}
          role="combobox"
          aria-expanded={open}
          aria-label={t("select-partner")}
          className="w-full justify-between border border-input"
        >
          {value
            ? partnersQuery.data?.find(
                (partner) => partner.id?.toString() === value,
              )?.name
            : t("select-partner")}
          <ChevronDown
            className={cn(
              "ml-2 hidden h-4 w-4 shrink-0 opacity-50 transition-transform duration-300 lg:block",
              open && "-scale-y-100",
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: buttonRef.current?.clientWidth }}
        className="p-0"
      >
        <Command
          filter={(value, search) => {
            const partner = partnersQuery.data?.find(
              (partner) => partner.id?.toString() === value,
            );
            if (partner?.name?.toLowerCase().includes(search.toLowerCase()))
              return 1;
            return 0;
          }}
        >
          <CommandInput placeholder={t("select-partner")} />
          <CommandList>
            <CommandEmpty>{t("partner-not-found")}</CommandEmpty>
            <CommandGroup>
              {(partnersQuery.data ?? []).map((partner) => (
                <CommandItem
                  key={partner.id}
                  value={partner.id?.toString()}
                  onSelect={(partnerId) => {
                    onSelect(partnerId);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 size-4 flex-shrink-0",
                      value === partner.id?.toString()
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  <span>{partner.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
