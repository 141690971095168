import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";
import { NewPartner, Partner } from "@/api/generated/data-contracts";

export function useMutateCreatePartner(
  options?: Omit<
    UseMutationOptions<Partner, HttpResponse<void, Error>, NewPartner>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<Partner, HttpResponse<void, Error>, NewPartner>({
    ...options,
    mutationFn: async (newPartner) => {
      const response = await apiClient.partnersCreate(newPartner);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partners"] });
    },
  });
}
