import { Button } from "@/components/ui/button";
import * as Sentry from "@sentry/react";

type ErrorBoundaryProps = Parameters<Sentry.FallbackRender>[0];

export function ErrorBoundaryFallback({
  error,
  resetError,
}: Readonly<ErrorBoundaryProps>) {
  return (
    <div className="grid h-screen w-screen place-content-center overflow-hidden">
      <div className="flex w-full flex-grow flex-col items-center justify-center gap-10 rounded-md bg-[#FFFFFF] p-5 shadow">
        <h1 className="text-bold text-2xl">Something went wrong</h1>
        <pre>{error.message}</pre>
        <Button onClick={resetError}>Try again</Button>
      </div>
    </div>
  );
}
