import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { useCallback } from "react";

export const STATE_TRANSTALION_KEYS: Record<number, string> = {
  0: "not-set",
  10: "removed",
  100: "partner-waiting-for-approval",
  110: "partner-declined",
  120: "partner-approved",
  200: "onsite-order-received",
  210: "onsite-customer-unreachable",
  220: "onsite-customer-contacted",
  290: "onsite-rejected",
  300: "freight-intransit",
  400: "also-checkin",
  405: "also-cleanup",
  410: "also-sorting-and-datawipe",
  420: "also-classification",
  430: "also-waiting-in-warehouse",
  450: "also-order-completed",
  451: "also-returned-to-partner",
  452: "also-returned-to-endcustomer",
  453: "also-destroyed",
  454: "also-warehouse",
  455: "also-sold",
  456: "also-solving",
  457: "also-waiting-for-repair",
  458: "also-sent-to-repair",
  459: "also-removed-via-inventory",
  460: "also-stored-to-warehouse",
  461: "also-did-not-arrive",
};

export function useStateIdTranslation() {
  const { t } = useTranslation("", { keyPrefix: "common.state" });

  return useCallback(
    (id: number) => {
      if (!STATE_TRANSTALION_KEYS[id]) {
        Sentry.captureEvent({
          message: `Unknown state id ${id}`,
          level: "warning",
        });
      }
      return t(STATE_TRANSTALION_KEYS[id] ?? "unknown-state");
    },
    [t],
  );
}
