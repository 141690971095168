import { msalInstance } from "@/auth";
import { loginRequest } from "@/authconfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Api } from "./generated/Api";
import { toast } from "sonner";
import { ENV } from "@/env";

export const apiClient = new Api({
  baseUrl: ENV.apiUrl,
  baseApiParams: {
    secure: true,
  },
  securityWorker: async () => {
    const response = await msalInstance
      .acquireTokenSilent(loginRequest)
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenRedirect(loginRequest);
        }
        const keys = Object.keys(localStorage).filter((key) =>
          key.includes("login.windows.net"),
        );
        keys.forEach((key) => localStorage.removeItem(key));
        msalInstance.clearCache();
        window.location.replace(`/login?redirect=${window.location.pathname}`);
        toast.error("Your session has expired. Please log in again.");
      });

    if (!response) throw new Error("Could not acquire token");

    return {
      headers: {
        Authorization: `Bearer ${response.accessToken}`,
      },
    };
  },
});
